export const toolSk = {
  tools: {
    title: "Nástroje",
    toolTips: {
      edit: "Upravit nástroj {tool}",
      delete: "Odstranit nástroj {tool}",
      gameplays: "Zbývající počet denních pokusů: {counts}",
    },
    editTitle: "Upravit nástroj",
    newTitle: "Nový nástroj",
    addTool: "Přidat nástroj",
    errors: {
      notFound: "Nástroj id {id} nebyl nalezen",
      notYourRole: "Nemáte práva zobrazit nástroj id {id}",
      nameExists: "Nástroj s nazvem `{name}` již existuje",
      codeExists: "Nástroj s kodem `{code}` již existuje",
      commonGET_TOOL_BY_ID: "Chyba při získávání dat o nástrojích",
      commonDELETE_TOOL_BY_ID: "Chyba při odstraňování nástroje",
      commonDELETE_TOOLS: "Nepodařilo se odstranit nástroj",
      cantGetTools: "Chyba při získávání dat o nástrojích",
      cantStoreGameResults: "Chyba při ukládání výsledků hry",
      cantGetGameResults: "Chyba při získávání dat o výsledcích her",
    },
    settings: {
      title: "Nastavení",
      keys: {
        size: "Velikost",
        bpm: "BPM",
        volume: "Hlasitost",
        amountOfCharacters: "Počet znaků",
        rows: "Řádky",
        cols: "Sloupce",
        level: "Level",
        fieldCount: "Počet prvků",
        storeResults: "Uložit výsledek",
        customDescription: "Popis",
        dailyAttempts: "Počet pokusů / den",
      },
    },
    types: {
      game: "Hra",
      measurement: "Měření",
      other: "Ostatní",
    },
    form: {
      addTool: "Přidat nástroj",
      name: "Název",
      code: "Kód",
      client_name: "Název pro klienta",
      description: "Popis",
      type: "Typ",
      settings: "Nastavení",
      company: "Firmy",
      toolChanged: "Nástroj byl upraven",
      toolChangedFail: "Nepodařilo se upravit nástroj",
      toolAdded: "Nový nástroj byl vytvořen",
      toolAddFail: "Nepodařilo se vytvořit nový nástroj",
      gameResultStored: "Výsledek hry byl uložen",
    },
    table: {
      header: {
        name: "Název",
        type: "Typ",
        code: "Kód",
        company: "Firmy",
        actions: "Akce",
      },
      deleteToolQuestion:
        "Opravdu chcete odstranit nástroj <strong>{tool}</strong>?",
      searchTools: "Hledat nástroj",
      toolDeleted: "Nástroj {tool} byl odstraněn",
      toolsDeleted: "Nástroje byly odstraněny",
      noRecords: "Nebyly nalezeny žádné nástroje",
    },
    modules: {
      import_leads: {
        form: {
          file: "Importný súbor (XLSX)",
        },
        table: {
          headers: {
            email: "Email",
            task1: "Úloha 1",
            task2: "Úloha 2",
            task3: "Úloha 3",
            task4: "Úloha 4",
            task5: "Úloha 5",
            error: "Chyba",
          },
        },
        import: "Importovať",
        new_import: "Nový import",
        results: "Výsledok importu",
        status: "Stav",
        validValues: "Platné hodnoty",
        errorValues: "Chybné hodnoty",
        validations: {
          emailExists: "Používateľ s emailom {email} neexistuje",
        },
        result: {
          reasons: {
            value_inserted: "Používateľ vložený",
            value_not_inserted: "Používateľ nebol vložený",
            value_exists: "Hodnota pre zadaný deň už existuje",
          },
        },
      },
      export_diagnostics: {
        form: {
          dateFrom: "Dátum od",
          dateTo: "Dátum do",
        },
        export: "Exportovať",
        result: "Výsledok",
      },
      import_diagnostics: {
        form: {
          file: "Importný súbor (XLSX)",
        },
        table: {
          headers: {
            email: "Email",
            task1: "Úloha 1",
            task2: "Úloha 2",
            task3: "Úloha 3",
            task4: "Úloha 4",
            task5: "Úloha 5",
            error: "Chyba",
            dominance_eye: "Oko",
            dominance_hand: "Ruka",
            dominance_leg: "Noha",
            dominance_ear: "Rytmus",
            eyesight_flexibility_both_eyes: "Flexibilita",
            eyesight_accommodation_right_eye: "Akomodácia P",
            eyesight_accommodation_left_eye: "Akomodácia L",
          },
        },
        import: "Importovať",
        new_import: "Nový import",
        results: "Výsledok importu",
        status: "Stav",
        validValues: "Platné hodnoty",
        errorValues: "Chybné hodnoty",
        type: "Typ",
        date: "Dátum merania",
        valueTypes: {
          s2board_measurements: "Meranie na doske SensesBoard",
          in_office_measurements: "Vyšetrenie funkčnosti očných svalov",
          eye_flexibility_measurements: "Flexibilita zraku",
          optics_measurements: "MySenses - měření",
          eye_stability_measurements: "Stabilita zraku",
        },
        validations: {
          s2board_measurements: {
            email_not_found: "Používateľ s emailom {email} neexistuje",
          },
        },
        result: {
          reasons: {
            value_inserted: "Hodnota vložená",
            value_exists: "Hodnota pre zadaný deň už existuje",
            value_not_inserted: "Hodnotu sa nepodarilo vložiť",
          },
        },
      },
      sleep_diary: {
        title: "Spánkový denník",
        days: {
          1: "Po",
          2: "Ut",
          3: "St",
          4: "Št",
          5: "Pia",
          6: "So",
          7: "Ne",
        },
        rows: {
          bed_time_at: {
            title: "Čas uloženia do postele",
            note: "Idem si ľahnúť, ale ešte nemusím spať (napr. pozerám televíziu, čítam si)",
          },
          lights_off_at: {
            title: "Čas zhasnutia",
            note: "Skutočný čas, kedy som pripravený/spripravená spať",
          },
          fall_asleep_in: {
            title: "Trvanie zaspávania",
            note: "Doba, počas ktorej som zaspal/zaspala",
          },
          wake_up_at: {
            title: "Čas prebudenia",
            note: "Doba, kedy som sa prebudil/a (ešte môžem zostať v posteli)",
          },
          of_bed_at: {
            title: "Čas vstávania",
            note: "Doba, kedy som vstal/a z postele",
          },
          awake_moments_count: {
            title: "Počet nočných prebudení",
            note: "Koľkokrát som sa za noc prebudil/a a pamätám si to (napr. nemohol/a som potom zaspať)",
          },
          time_in_bed: {
            title: "Doba strávená v posteli",
            note: "Doba medzi uložením do postele a časom vstávania. Vypočíta sa z: času uloženia do postele a času vstávania (idem si ľahnúť o 22:00, vstanem o 6:00 – v posteli som strávil/a 8 hodín)",
          },
        },
      },
    },
  },
};
