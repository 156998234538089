import { User } from "@/store/interfaces/User";
import { File } from "@/store/interfaces/File";

export interface Phase {
  index: number;
  name: string;
  status: "not-started" | "ready" | "running" | "finished";
  icon: string;
  video_id: number;
  video: File | null;
  iconClass: string;
  result: number;
  unit?: string | null;
  resultValidator?: (value: number) => number | null;
  askForValueText?: string | null;

  withoutTimer?: boolean | null;
}

export interface DiagnosticRecord {
  id: number | null;
  user_id: number | null;
  user: User | null;
  name: string;
  type:
    | "single_bool"
    | "single_range"
    | "single_range_bool_combined"
    | "historical"
    | "historical_range"
    | "historical_pdf";
  latest_data: DiagnosticRecordDataItem[];
  data: DiagnosticRecordDataItem[];
  created_at: string | null;
  updated_at: string | null;
  note?: string | null;
  touched: boolean;
}
export interface S2BoardResult {
  total: number;
  tasks: [{ taskNr: number; time: number; name: string }];
}

export interface VisionEnergyResult {
  total: number;
  tasks: [{ taskNr: number; time: number; name: string }];
}

export interface InOfficeResult {
  total: number;
  tasks: [{ taskNr: number; time: number; name: string }];
}

export interface EyeFlexibilityResult {
  total: number;
  tasks: [{ taskNr: number; time: number; name: string }];
}

export interface OpticsResult {
  total: number;
  tasks: [{ taskNr: number; time: number; name: string }];
}
export interface EyeStabilityResult {
  total: number;
  tasks: [{ taskNr: number; time: number; name: string }];
}

export interface DiagnosticRecordDataItem {
  id: number | null;
  value: string | number | boolean | null | File | S2BoardResult;
  created_at: string | null;
  created_by: string | null;
  updated_at: string | null;
  updated_by: string | null;
  marked_to_delete: boolean;
  timestamp_id?: number;
}

export interface ClientRadarData {
  client_id: number;
  full_name: string;
  color: string;
  current_data: [];
  previous_data: [];
  display: boolean;
}

export const defaultDiagnosticSet = [
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "single_bool",
    name: "in_office_enabled",
    touched: false,
    latest_data: [
      {
        id: null,
        value: 0,
        created_at: null,
        updated_at: null,
        marked_to_delete: false,
      },
    ],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "in_office_measurements",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "single_bool",
    name: "eye_flexibility_enabled",
    touched: false,
    latest_data: [
      {
        id: null,
        value: 0,
        created_at: null,
        updated_at: null,
        marked_to_delete: false,
      },
    ],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "eye_flexibility_measurements",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "single_bool",
    name: "optics_enabled",
    touched: false,
    latest_data: [
      {
        id: null,
        value: 0,
        created_at: null,
        updated_at: null,
        marked_to_delete: false,
      },
    ],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "optics_measurements",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "single_bool",
    name: "eye_stability_enabled",
    touched: false,
    latest_data: [
      {
        id: null,
        value: 0,
        created_at: null,
        updated_at: null,
        marked_to_delete: false,
      },
    ],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "eye_stability_measurements",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "single_bool",
    name: "vision_energy_enabled",
    touched: false,
    latest_data: [
      {
        id: null,
        value: 0,
        created_at: null,
        updated_at: null,
        marked_to_delete: false,
      },
    ],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "vision_energy_measurements",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "single_bool",
    name: "s2board_enabled",
    touched: false,
    latest_data: [
      {
        id: null,
        value: 0,
        created_at: null,
        updated_at: null,
        marked_to_delete: false,
      },
    ],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "s2board_measurements",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "single_bool",
    name: "dominance_eye",
    touched: false,

    latest_data: [
      {
        id: null,
        value: false,
        created_at: null,
        updated_at: null,
        marked_to_delete: false,
      },
    ],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "single_bool",
    name: "dominance_hand_bool_custom_placement",
    touched: false,
    latest_data: [
      {
        id: null,
        value: 0,
        created_at: null,
        updated_at: null,
        marked_to_delete: false,
      },
    ],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "single_range_bool_combined",
    name: "dominance_hand",
    touched: false,

    latest_data: [
      {
        id: null,
        value: 0,
        created_at: null,
        updated_at: null,
        marked_to_delete: false,
      },
    ],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "single_bool",
    name: "dominance_leg_bool_custom_placement",
    touched: false,

    latest_data: [
      {
        id: null,
        value: 0,
        created_at: null,
        updated_at: null,
        marked_to_delete: false,
      },
    ],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "single_range_bool_combined",
    name: "dominance_leg",
    touched: false,

    latest_data: [
      {
        id: null,
        value: 0,
        created_at: null,
        updated_at: null,
        marked_to_delete: false,
      },
    ],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "single_bool",
    name: "dominance_ear",
    touched: false,

    latest_data: [
      {
        id: null,
        value: false,
        created_at: null,
        updated_at: null,
        marked_to_delete: false,
      },
    ],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical_pdf",
    name: "anamnesis_docs",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },

  // Ballance
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "single_bool",
    name: "balance_middleEar_value",
    touched: false,

    latest_data: [
      {
        id: null,
        value: false,
        created_at: null,
        updated_at: null,
        marked_to_delete: false,
      },
    ],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "single_bool",
    name: "balance_rightLeg_opened_eyes",
    touched: false,

    latest_data: [
      {
        id: null,
        value: false,
        created_at: null,
        updated_at: null,
        marked_to_delete: false,
      },
    ],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "single_bool",
    name: "balance_rightLeg_closed_eyes",
    touched: false,

    latest_data: [
      {
        id: null,
        value: false,
        created_at: null,
        updated_at: null,
        marked_to_delete: false,
      },
    ],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "single_bool",
    name: "balance_leftLeg_opened_eyes",
    touched: false,

    latest_data: [
      {
        id: null,
        value: false,
        created_at: null,
        updated_at: null,
        marked_to_delete: false,
      },
    ],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "single_bool",
    name: "balance_leftLeg_closed_eyes",
    touched: false,

    latest_data: [
      {
        id: null,
        value: false,
        created_at: null,
        updated_at: null,
        marked_to_delete: false,
      },
    ],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "single_textarea",
    name: "balance_note_value",
    touched: false,

    latest_data: [
      {
        id: null,
        value: "",
        created_at: null,
        updated_at: null,
        marked_to_delete: false,
      },
    ],
    data: [] as DiagnosticRecordDataItem[],
  },
  // Hearing
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "hearing_left_ear",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "hearing_right_ear",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  // Concentration
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "concentration_concentration",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  // Coordination
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "coordination_reaction_time",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "coordination_hand_eye",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "coordination_gono_go",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  // Neurovision
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "neurovision_ShorTerm_memory",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "neurovision_field_of_view_efficiency",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  // EYESIGHT
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "eyesight_diopter_right_eye",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "eyesight_diopter_left_eye",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "single_bool",
    name: "eyesight_correction_glasses",
    touched: false,

    latest_data: [
      {
        id: null,
        value: false,
        created_at: null,
        updated_at: null,
        marked_to_delete: false,
      },
    ],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "single_bool",
    name: "eyesight_correction_glasses_reading",
    touched: false,

    latest_data: [
      {
        id: null,
        value: false,
        created_at: null,
        updated_at: null,
        marked_to_delete: false,
      },
    ],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "single_bool",
    name: "eyesight_correction_contact_lenses_soft",
    touched: false,

    latest_data: [
      {
        id: null,
        value: false,
        created_at: null,
        updated_at: null,
        marked_to_delete: false,
      },
    ],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "single_bool",
    name: "eyesight_correction_contact_lenses_hard",
    touched: false,

    latest_data: [
      {
        id: null,
        value: false,
        created_at: null,
        updated_at: null,
        marked_to_delete: false,
      },
    ],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "eyesight_sharpness_both_eyes",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "eyesight_sharpness_right_eye",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "eyesight_sharpness_left_eye",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "eyesight_sharpness_depth",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "eyesight_refocusing_both_eyes",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "eyesight_refocusing_side",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "eyesight_refocusing_right_eye",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "eyesight_refocusing_left_eye",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },

  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "eyesight_motility_both_eyes",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "eyesight_motility_right_eye",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "eyesight_motility_left_eye",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "eyesight_accommodation_both_eyes",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "eyesight_accommodation_right_eye",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "eyesight_accommodation_left_eye",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "eyesight_flexibility_both_eyes",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "eyesight_contrastSensitivity_both_eyes",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "eyesight_contrastSensitivity_right_eye",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "eyesight_contrastSensitivity_left_eye",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "eyesight_muscles_far",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "eyesight_muscles_close",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
  {
    id: null,
    user_id: null,
    user: null,
    created_at: null,
    updated_at: null,
    type: "historical",
    name: "eyesight_other_system_understanding",
    touched: false,

    latest_data: [] as DiagnosticRecordDataItem[],
    data: [] as DiagnosticRecordDataItem[],
  },
];

export const historicalDataInputTypes = {
  anamnesis_docs: {
    type: "file-input",
    defaultValue: null,
    settings: {
      fileType: "pdf",
    },
  },
  eyesight_muscles_far: {
    type: "number-slider",
    defaultValue: 2,
    settings: {
      min: 0,
      max: 20,
      step: 2,
      "show-stops": true,
      "show-input": true,
    },
  },
  eyesight_muscles_close: {
    type: "number-slider",
    defaultValue: 2,
    settings: {
      min: 0,
      max: 40,
      step: 2,
      "show-stops": true,
      "show-input": true,
    },
  },
  eyesight_diopter_right_eye: {
    type: "number",
    defaultValue: 0,
    settings: {
      min: 0,
      max: 100,
    },
  },
  concentration_concentration: {
    type: "number",
    defaultValue: 0,
    settings: {
      min: 30,
      max: 1000,
    },
  },
  eyesight_diopter_left_eye: {
    type: "number",
    defaultValue: 0,
    settings: {
      min: 0,
      max: 100,
    },
  },
};
