export const dashboardEn = {
  dashboard: {
    title: "Dashboard",
    noModules: "You have no assigned dashboard modules to view",
    nrOfClients: "Number of clients",
    errors: {
      cantGetDashboard: "Failed to download data for dashboard",
      cantGetDashboardModules: "Failed to fetch dashboard modules",
    },
    form: {
      modules: "Allowed modules",
    },

    widgets: {
      results: {
        title: "Results",
        noRecords: "No results available at the moment.",
      },
      diagnostics: {
        title: "Diagnostic",
        noRecords: "No diagnostics available at the moment.",
      },
      games: {
        title: "Neurocognitive training",
        noRecords: "No games available at the moment.",
        newGamesAvailable: "New games available",
        play: "New game",
        last_gameplay: "Last gameplay",
        no_gameplay_record: "You haven't played this game yet",
      },
      training: {
        title: "Neurovisual training",
        noRecords: "No training available at the moment.",
      },
      senaptec: {
        title: "Senaptec",
        measurements: "Measurement results",
        historyListTitle: "Measurement history",
      },
      senses_board: {
        title: "SensesBoard",
        measurements: "Measurement results",
        historyListTitle: "Measurement history",
      },
      "in-office": {
        title: "Examination of oculomotor muscle functionality",
        measurements: "Measurement results",
        historyListTitle: "Measurement history",
      },
      eye_flexibility: {
        title: "Eye flexibility",
        measurements: "Measurement results",
        historyListTitle: "Measurement history",
      },
      optics: {
        title: "MySenses",
        measurements: "Measurement results",
        historyListTitle: "Measurement history",
      },
      eye_stability: {
        title: "Eye stability",
        measurements: "Measurement results",
        historyListTitle: "Measurement history",
      },
    },
  },
};
