<template>
  <div class="card">
    <div class="card-header border-0 pt-6 d-none">
      <div class="card-title">
        <!--begin::Search-->
        <div class="d-flex align-items-center position-relative my-1">
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="/media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            v-model="search"
            @input="searchItems()"
            class="form-control form-control-solid w-250px ps-15"
            v-bind:placeholder="translate('.searchRecords')"
          />
        </div>
        <!--end::Search-->
      </div>
    </div>
    <div class="card-body pt-0">
      <Datatable
        v-if="history.length"
        :sort-label="sorting.columnName"
        :order="sorting.order"
        :table-header="header"
        :row-class-callback="
          (row) => {
            return 'activity-' + row.activity;
          }
        "
        :loading="loading"
        :table-data="history"
        :empty-table-text="''"
        :total="pagination.total"
        :rows-per-page="pagination.per_page"
        v-on:current-change="doPageChange($event)"
        v-on:sort="doSort($event)"
        v-on:items-per-page-change="doItemsPerPageChange($event)"
      >
        <template v-slot:cell-created_at="{ row: record }">
          <i18n-d :value="getDate(record.created_at)" />
          <el-tooltip
            v-if="record.created_by"
            :content="
              record.created_by?.name + ' ' + record.created_by?.surname
            "
          >
            <i class="ms-3 fa fa-user"></i>
          </el-tooltip>
          <i>&nbsp;&nbsp;{{ record.created_by?.initials }}</i>
        </template>
        <template v-slot:cell-activity="{ row: record }">
          {{ translate("activities." + record.activity) }}
        </template>
        <template v-slot:cell-data="{ row: record }">
          <component :is="getRendererName(record)" :record="record" />
        </template>
      </Datatable>
      <div
        v-if="!loading && history.length === 0"
        class="alert alert-dismissible d-flex flex-column flex-sm-row w-100 p-5 mb-10"
      >
        <span class="svg-icon svg-icon-2hx svg-icon-info me-4">
          <inline-svg src="/media/icons/duotune/general/gen044.svg" />
        </span>
        <div class="d-flex align-items-center">
          <h6 class="mb-0 text-inverse-info">{{ translate(".noRecords") }}</h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import "./sass/CrmUserHistory.scss";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import store from "@/store";
import { User } from "@/store/interfaces/User";
import { useTranslate } from "@/composables/translate";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { UserCrmHistory } from "@/store/interfaces/CRM";
import { useToast } from "vue-toastification";
import { PaginationResponse, SortingPair } from "@/store/interfaces/Datatables";
import debounce from "lodash/debounce";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import SingleDataRenderer from "@/views/crm/components/renderers/SingleDataRenderer.vue";
import LinkedDataRenderer from "@/views/crm/components/renderers/LinkedDataRenderer.vue";
import MultipleDataRenderer from "@/views/crm/components/renderers/MultipleDataRenderer.vue";
import WithImageDataRenderer from "@/views/crm/components/renderers/WithImageDataRenderer.vue";
import CustomDataRenderer from "@/views/crm/components/renderers/CustomDataRenderer.vue";

export default defineComponent({
  name: "crm-user-history",
  props: {
    user: {
      type: Object as () => User,
      required: true,
    },
  },
  components: {
    SingleDataRenderer,
    LinkedDataRenderer,
    MultipleDataRenderer,
    WithImageDataRenderer,
    CustomDataRenderer,
    Datatable,
  },
  setup(props) {
    const { translate } = useTranslate("crm.history");
    onMounted(() => {
      loadHistory();
    });
    watch(
      () => props.user.id,
      () => {
        loadHistory();
      }
    );

    const header = ref([
      {
        name: translate("table.created_at"),
        key: "created_at",
        sortable: true,
        sortingField: "created_at",
      },
      {
        name: translate("table.activity"),
        key: "activity",
        sortable: true,
        sortingField: "activity",
      },
      {
        name: translate("table.data"),
        key: "data",
        sortable: false,
      },
    ]);

    const loading = ref(false);

    const history = ref<Array<UserCrmHistory>>([]);

    const sorting = computed(() => {
      return store.getters.getCrmUserHistorySorting as SortingPair;
    });

    const pagination = computed(() => {
      return store.getters.getCrmUserHistoryPagination as PaginationResponse;
    });

    const search = ref<string>("");
    const reFetch = () => {
      loadHistory();
    };
    const loadHistory = () => {
      if (props.user.id) {
        loading.value = true;
        store
          .dispatch(Actions.CRM_LOAD_USER_HISTORY, {
            page: pagination.value.current_page,
            per_page: pagination.value.per_page,
            sorting: sorting.value,
            search: search.value,
            user_id: props.user ? props.user.id : loadedUser.value.id,
          })
          .then((results) => {
            history.value.splice(0, history.value.length, ...results);
          })
          .catch(() => {
            useToast().warning(store.getters.getLastError);
          })
          .finally(() => {
            loading.value = false;
          });
      }
    };

    const doSort = (sorting) => {
      store.commit(Mutations.CRM_SET_USER_HISTORY_SORTING, sorting);
      loadHistory();
    };

    const doPageChange = (page) => {
      store.commit(Mutations.CRM_SET_USER_HISTORY_PAGINATION, {
        ...pagination.value,
        ...{ current_page: page },
      });
      loadHistory();
    };
    const doItemsPerPageChange = (itemsPerPage) => {
      store.commit(Mutations.CRM_SET_USER_HISTORY_PAGINATION, {
        ...pagination.value,
        ...{ per_page: itemsPerPage },
      });
      loadHistory();
    };

    const searchItems = debounce(() => {
      loadHistory();
    }, 500);

    const loadedUser = computed(() => {
      return store.getters.getLoadedUser as User;
    });

    const getDate = (dateString: string): number => {
      return Date.parse(dateString);
    };

    const getRendererName = (record: UserCrmHistory) => {
      return (
        record.type.replace("_renderer", "").replace("_", "-") +
        "-data-renderer"
      );
    };

    return {
      reFetch,
      getRendererName,
      getDate,
      sorting,
      pagination,
      header,
      search,
      loading,
      searchItems,
      doSort,
      doPageChange,
      doItemsPerPageChange,
      history,
      translate,
      loadedUser,
    };
  },
});
</script>
