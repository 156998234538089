import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row mb-6 d-none" }
const _hoisted_2 = { class: "col-lg-4 col-form-label fw-bold fs-6" }
const _hoisted_3 = {
  class: "col-lg-8 position-relative fw-bold",
  style: {"top":"10px"}
}
const _hoisted_4 = { class: "row mb-6" }
const _hoisted_5 = { class: "col-lg-4 col-form-label fw-bold fs-6" }
const _hoisted_6 = { class: "col-lg-12 d-md-flex d-block flex-wrap overflow-auto mh-150px" }
const _hoisted_7 = { class: "d-flex" }
const _hoisted_8 = { class: "btn-group" }
const _hoisted_9 = { class: "col-lg-12 d-md-flex d-block flex-wrap overflow-auto mh-150px" }
const _hoisted_10 = { class: "d-flex" }
const _hoisted_11 = { class: "btn-group" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col-lg-4 col-form-label fw-bold fs-6" }
const _hoisted_14 = { class: "col-lg-8 fv-row" }
const _hoisted_15 = { class: "row mt-10" }
const _hoisted_16 = { class: "col-lg-4 col-form-label fw-bold fs-6" }
const _hoisted_17 = { class: "col-lg-8 d-flex align-items-center" }
const _hoisted_18 = { class: "form-check form-check-solid form-switch fv-row" }
const _hoisted_19 = {
  key: 0,
  class: "row mt-5"
}
const _hoisted_20 = { class: "col" }
const _hoisted_21 = { class: "text" }
const _hoisted_22 = {
  key: 1,
  class: "row mt-5"
}
const _hoisted_23 = { class: "col" }
const _hoisted_24 = ["placeholder"]
const _hoisted_25 = { class: "card-footer" }
const _hoisted_26 = ["disabled"]
const _hoisted_27 = { class: "indicator-label" }
const _hoisted_28 = { class: "indicator-progress" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, {
    class: "form",
    id: "user_contact_event_form",
    onSubmit: _ctx.submit,
    "validation-schema": _ctx.validationSchema
  }, {
    default: _withCtx(() => [
      _createElementVNode("h4", null, _toDisplayString(_ctx.translate("table.addRecord", "notifications")), 1),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("label", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.translate("form.reason")), 1)
        ]),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate("reasons." + _ctx.reason)), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", _hoisted_5, [
          _createElementVNode("span", null, _toDisplayString(_ctx.translate("form.contacted_via")), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setVia('phone'))),
                type: "button",
                class: _normalizeClass(["btn", 
                _ctx.eventData.contacted_via === 'phone'
                  ? 'btn-primary'
                  : 'btn-light-primary'
              ])
              }, [
                _cache[10] || (_cache[10] = _createElementVNode("span", { class: "svg-icon svg-icon-2" }, null, -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.translate("via.phone")), 1)
              ], 2),
              _createElementVNode("button", {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setVia('sms'))),
                type: "button",
                class: _normalizeClass(["btn", 
                _ctx.eventData.contacted_via === 'sms'
                  ? 'btn-primary'
                  : 'btn-light-primary'
              ])
              }, [
                _cache[11] || (_cache[11] = _createElementVNode("span", { class: "svg-icon svg-icon-2" }, null, -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.translate("via.sms")), 1)
              ], 2),
              _createElementVNode("button", {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setVia('email'))),
                type: "button",
                class: _normalizeClass(["btn", 
                _ctx.eventData.contacted_via === 'email'
                  ? 'btn-primary'
                  : 'btn-light-primary'
              ])
              }, [
                _cache[12] || (_cache[12] = _createElementVNode("span", { class: "svg-icon svg-icon-2" }, null, -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.translate("via.email")), 1)
              ], 2),
              _createElementVNode("button", {
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setVia('in_person'))),
                type: "button",
                class: _normalizeClass(["btn", 
                _ctx.eventData.contacted_via === 'in_person'
                  ? 'btn-primary'
                  : 'btn-light-primary'
              ])
              }, [
                _cache[13] || (_cache[13] = _createElementVNode("span", { class: "svg-icon svg-icon-2" }, null, -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.translate("via.in_person")), 1)
              ], 2),
              _createElementVNode("button", {
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setVia('not_contacted'))),
                type: "button",
                class: _normalizeClass(["btn", 
                _ctx.eventData.contacted_via === 'not_contacted'
                  ? 'btn-primary'
                  : 'btn-light-primary'
              ])
              }, [
                _cache[14] || (_cache[14] = _createElementVNode("span", { class: "svg-icon svg-icon-2" }, null, -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.translate("via.not_contacted")), 1)
              ], 2)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("button", {
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setVia('does_not_pick_up'))),
              type: "button",
              class: _normalizeClass(["btn", 
              _ctx.eventData.contacted_via === 'does_not_pick_up'
                ? 'btn-warning'
                : 'btn-light-warning'
            ])
            }, [
              _cache[15] || (_cache[15] = _createElementVNode("span", { class: "svg-icon svg-icon-2" }, null, -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.translate("via.does_not_pick_up")), 1)
            ], 2)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("label", _hoisted_13, [
          _createElementVNode("span", null, _toDisplayString(_ctx.translate("form.result")), 1)
        ]),
        _createElementVNode("div", _hoisted_14, [
          _withDirectives(_createElementVNode("textarea", {
            name: "otherPhones",
            class: "form-control form-control-lg form-control-solid",
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.eventData.result) = $event))
          }, null, 512), [
            [_vModelText, _ctx.eventData.result]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.translate("form.nextContactEvent")), 1),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _withDirectives(_createElementVNode("input", {
              class: "form-check-input w-45px h-30px",
              type: "checkbox",
              id: "nextContactEnabled",
              value: "nextContactEnabled",
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.nextContactEnabled) = $event))
            }, null, 512), [
              [_vModelCheckbox, _ctx.nextContactEnabled]
            ]),
            _cache[16] || (_cache[16] = _createElementVNode("label", {
              class: "form-check-label",
              for: "nextContactEnabled"
            }, null, -1))
          ])
        ])
      ]),
      (_ctx.nextContactEnabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _createVNode(_component_el_date_picker, {
                modelValue: _ctx.eventData.next_contact_date,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.eventData.next_contact_date) = $event)),
                size: "large",
                format: "DD.MM.YYYY",
                teleported: false,
                "value-format": "YYYY-MM-DD HH:mm:ss",
                type: "date",
                editable: false,
                "disabled-date": _ctx.disabledDate,
                placeholder: _ctx.translate('form.nextDay'),
                name: "next_contact_date"
              }, {
                default: _withCtx((cell) => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["cell", {
                current: cell.isCurrent,
                'cursor-default bg-light-dark': _ctx.disabledDate(cell.date),
              }])
                  }, [
                    _createElementVNode("span", _hoisted_21, _toDisplayString(cell.text), 1)
                  ], 2)
                ]),
                _: 1
              }, 8, ["modelValue", "disabled-date", "placeholder"])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.nextContactEnabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              _withDirectives(_createElementVNode("textarea", {
                name: "next_contact_message",
                placeholder: _ctx.translate('form.note'),
                class: "form-control form-control-lg form-control-solid",
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.eventData.next_contact_message) = $event))
              }, null, 8, _hoisted_24), [
                [_vModelText, _ctx.eventData.next_contact_message]
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_25, [
        _createElementVNode("button", {
          disabled: !_ctx.eventData.contacted_via,
          ref: "submitButtonRef",
          type: "submit",
          id: "user_contact_event_submit",
          class: "btn btn-primary"
        }, [
          _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.translate("save", "common")), 1),
          _createElementVNode("span", _hoisted_28, [
            _createTextVNode(_toDisplayString(_ctx.translate("pleaseWait", "common")) + " ", 1),
            _cache[17] || (_cache[17] = _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" }, null, -1))
          ])
        ], 8, _hoisted_26)
      ])
    ]),
    _: 1
  }, 8, ["onSubmit", "validation-schema"]))
}