<style lang="scss"></style>
<template>
  <div class="card">
    <div class="card-header border-0 pt-6 d-none">
      <div class="card-title">
        <!--begin::Search-->
        <div class="d-flex align-items-center position-relative my-1">
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="/media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            v-model="search"
            @input="searchItems()"
            class="form-control form-control-solid w-250px ps-15"
            v-bind:placeholder="translate('.searchRecords')"
          />
        </div>
        <!--end::Search-->
      </div>
    </div>
    <div class="card-body pt-0">
      <Datatable
        v-if="notifications.length"
        :sort-label="sorting.columnName"
        :order="sorting.order"
        :table-header="header"
        :loading="loading"
        :table-data="notifications"
        :empty-table-text="''"
        :total="pagination.total"
        :rows-per-page="pagination.per_page"
        v-on:current-change="doPageChange($event)"
        v-on:sort="doSort($event)"
        v-on:items-per-page-change="doItemsPerPageChange($event)"
      >
        <template v-slot:cell-date_start="{ row: notification }">
          <i18n-d :value="getDate(notification.date_start)" />
          <el-tooltip
            v-if="notification.created_by_user"
            :content="
              notification.created_by_user?.name +
              ' ' +
              notification.created_by_user?.surname
            "
          >
            <i class="ms-3 fa fa-user"></i>
          </el-tooltip>
        </template>
        <template v-slot:cell-message="{ row: notification }">
          {{ notification.message }}
        </template>
        <template v-slot:cell-closed="{ row: notification }">
          <div
            class="text-start"
            @click="changeNotificationState(notification)"
          >
            <div
              class="btn btn-link btn-active-color-primary"
              :class="
                notification.closed == 1 || notification.closed == true
                  ? 'btn-color-success'
                  : 'btn-color-muted'
              "
            >
              <span class="svg-icon svg-icon-2x">
                <inline-svg src="/media/icons/duotune/general/gen043.svg" />
              </span>
            </div>
          </div>
        </template>
      </Datatable>
      <div
        v-if="!loading && notifications.length === 0"
        class="alert alert-dismissible d-flex flex-column flex-sm-row w-100 p-5 mb-10"
      >
        <span class="svg-icon svg-icon-2hx svg-icon-info me-4">
          <inline-svg src="/media/icons/duotune/general/gen044.svg" />
        </span>
        <div class="d-flex align-items-center">
          <h6 class="mb-0 text-inverse-info">
            {{ translate(".table.noRecords") }}
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import store from "@/store";
import { User, UserNotification } from "@/store/interfaces/User";
import { useTranslate } from "@/composables/translate";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useToast } from "vue-toastification";
import { PaginationResponse, SortingPair } from "@/store/interfaces/Datatables";
import debounce from "lodash/debounce";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import _ from "lodash";

export default defineComponent({
  name: "crm-user-notifications",
  props: {
    user: {
      type: Object as () => User,
      required: true,
    },
  },
  components: { Datatable },
  setup(props) {
    const { t, translate } = useTranslate("notifications");
    onMounted(() => {
      loadNotifications();
    });
    watch(
      () => props.user.id,
      () => {
        loadNotifications();
      }
    );

    const header = ref([
      {
        name: translate("table.header.date_start"),
        key: "date_start",
        sortable: true,
        sortingField: "date_start",
      },
      {
        name: translate("table.header.message"),
        key: "message",
        sortable: false,
        sortingField: "message",
      },
      {
        name: translate("table.header.closed"),
        key: "closed",
        sortable: true,
        sortingField: "closed",
      },
    ]);

    const loading = ref(false);

    const notifications = ref<Array<UserNotification>>([]);

    const sorting = computed(() => {
      return store.getters.getNotificationsSorting as SortingPair;
    });

    const pagination = computed(() => {
      return store.getters.getNotificationsPagination as PaginationResponse;
    });

    const search = ref<string>("");

    const loadNotifications = () => {
      if (props.user.id) {
        loading.value = true;
        store
          .dispatch(Actions.LOAD_NOTIFICATIONS, {
            page: pagination.value.current_page,
            per_page: pagination.value.per_page,
            sorting: sorting.value,
            search: search.value,
            for_user_id: props.user ? props.user.id : loadedUser.value.id,
          })
          .then((results) => {
            notifications.value.splice(
              0,
              notifications.value.length,
              ...results
            );
          })
          .catch(() => {
            useToast().warning(store.getters.getLastError);
          })
          .finally(() => {
            loading.value = false;
          });
      }
    };

    const doSort = (sorting) => {
      store.commit(Mutations.SET_NOTIFICATIONS_SORTING, sorting);
      loadNotifications();
    };

    const doPageChange = (page) => {
      store.commit(Mutations.SET_NOTIFICATIONS_PAGINATION, {
        ...pagination.value,
        ...{ current_page: page },
      });
      loadNotifications();
    };
    const doItemsPerPageChange = (itemsPerPage) => {
      store.commit(Mutations.SET_NOTIFICATIONS_PAGINATION, {
        ...pagination.value,
        ...{ per_page: itemsPerPage },
      });
      loadNotifications();
    };

    const searchItems = debounce(() => {
      loadNotifications();
    }, 500);

    const loadedUser = computed(() => {
      return store.getters.getLoadedUser as User;
    });

    const getDate = (dateString: string): number => {
      return Date.parse(dateString);
    };

    const changeNotificationState = (notification: UserNotification) => {
      const payload = _.cloneDeep(notification);
      payload.closed = payload.closed == 0 ? 1 : 0;
      Swal.fire({
        html: t(
          "notifications.table." +
            (notification.closed == 1
              ? "openNotificationQuestion"
              : "closeNotificationQuestion")
        ),
        icon: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn fw-bold btn-light-success",
          cancelButton: "btn fw-bold btn-light-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch(Actions.UPDATE_NOTIFICATION, payload).finally(() => {
            loadNotifications();
          });
        }
      });
    };

    return {
      changeNotificationState,
      getDate,
      sorting,
      pagination,
      header,
      search,
      loading,
      searchItems,
      doSort,
      doPageChange,
      doItemsPerPageChange,
      notifications,
      translate,
      loadedUser,
    };
  },
});
</script>
