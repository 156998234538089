import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { PaginationResponse } from "@/store/interfaces/Datatables";
import { SortingPair } from "@/store/interfaces/Datatables";
import type { UserGroup } from "@/store/interfaces/UserGroup";
import { Tool } from "@/store/interfaces/Tool";

@Module
export default class UserGroupModule extends VuexModule {
  redirectTo = "";
  loadedUserGroup: UserGroup = {} as UserGroup;
  userGroups: UserGroup[] = [];
  pagination: PaginationResponse = {} as PaginationResponse;
  sorting: SortingPair = {} as SortingPair;

  get getLoadedUserGroup(): UserGroup {
    return {
      ...this.loadedUserGroup,
      ...{
        force_diag_to_view: this.loadedUserGroup.id
          ? this.loadedUserGroup.force_diag_to_view
          : true,
        diagnostics:
          this.loadedUserGroup.diagnostics &&
          this.loadedUserGroup.diagnostics.length > 0
            ? this.loadedUserGroup.diagnostics
            : [],
      },
    } as UserGroup;
  }

  /**
   * Retreive current loaded user groups
   */
  get getUserGroups(): UserGroup[] {
    return this.userGroups;
  }

  /**
   * Get last pagination setup
   */
  get getUserGroupsPagination(): PaginationResponse {
    return this.pagination;
  }

  get getUserGroupsSorting() {
    return this.sorting;
  }

  @Mutation
  [Mutations.SET_LOADED_USER_GROUP](userGroup: UserGroup) {
    this.loadedUserGroup = userGroup;
  }

  @Mutation
  [Mutations.SET_USER_GROUPS](data) {
    this.userGroups = data.data;
  }

  @Mutation
  [Mutations.SET_USER_GROUPS_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_USER_GROUPS_SORTING](data) {
    this.sorting = data;
  }

  @Action
  [Actions.LOAD_USER_GROUPS](payload) {
    ApiService.setHeader();
    return new Promise<UserGroup[]>((resolve, reject) => {
      ApiService.post("user-groups", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_USER_GROUPS, data["user-groups"]);
            this.context.commit(
              Mutations.SET_USER_GROUPS_PAGINATION,
              data["user-groups"].pagination
            );
            resolve(data["user-groups"].data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "userGroups.errors.cantGetUserGroups"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.GET_USER_GROUP_BY_ID](id: number) {
    ApiService.setHeader();
    if (id == undefined) {
      return new Promise<UserGroup>((resolve) => {
        const userGroup = {} as UserGroup;
        this.context.commit(Mutations.SET_LOADED_USER_GROUP, userGroup);
        resolve(userGroup);
      });
    }
    return new Promise<UserGroup>((resolve, reject) => {
      ApiService.get(`user-group/${id}`)
        .then(({ data }) => {
          if (data.success) {
            const userGroup = data["user-group"];
            userGroup.tools.map((tool: Tool) => {
              tool.settings = { ...tool.settings, ...tool.pivot.settings };
            });
            this.context.commit(Mutations.SET_LOADED_USER_GROUP, userGroup);
            resolve(userGroup);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "userGroups.errors.commonGET_USER_GROUP_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_USER_GROUP_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<UserGroup>((resolve, reject) => {
      ApiService.delete(`user-group/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_USER_GROUP,
              data["user-group"]
            );
            resolve(data["user-group"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "userGroups.errors.commonDELETE_USER_GROUP_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_USER_GROUP](userGroup: UserGroup) {
    const payload = JSON.parse(JSON.stringify(userGroup));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`user-group/${userGroup.id}`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_USER_GROUP,
              data["user-group"]
            );
            resolve(data["user-group"]);
          } else {
            const i18string = data.i18;
            const i18params = { id: userGroup.id, name: userGroup.name };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "userGroups.form.userGroupChangedFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_USER_GROUP](userGroup: UserGroup) {
    const payload = JSON.parse(JSON.stringify(userGroup));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`user-group`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_USER_GROUP,
              data["user-group"]
            );
            resolve(data["user-group"]);
          } else {
            const i18string = data.i18;
            const i18params = { id: userGroup.id, name: userGroup.name };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "userGroups.form.userGroupAddFail"
          );
          reject();
        });
    });
  }
}
