<template>
  <div class="row align-items-center align-middle">
    <div class="col-10">
      <div
        v-if="record.data.description"
        v-html="record.data.description"
      ></div>
      <div
        v-if="record.data.description_i18"
        v-html="printTranslatedDescription(record)"
      ></div>
    </div>
    <div v-if="record.data.link" class="text-end col-2">
      <router-link
        class="btn btn-link btn-color-muted btn-active-color-primary"
        :target="'_blank'"
        :to="record.data.link"
      >
        <span v-if="record.data.icon" class="svg-icon svg-icon-2x">
          <inline-svg :src="'/media/icons/duotune/' + record.data.icon" />
        </span>
        <span v-else>{{ record.data.link }}</span>
      </router-link>
    </div>
  </div>
</template>
<script lang="ts">
import { UserCrmHistory } from "@/store/interfaces/CRM";
import { useCrm } from "@/composables/crm";

export default {
  name: "linked-data-renderer",
  props: {
    record: {
      type: Object as () => UserCrmHistory,
      required: true,
      readonly: false,
    },
  },
  setup() {
    const { translateDescription } = useCrm();
    const printTranslatedDescription = (record: UserCrmHistory): string => {
      return translateDescription(record);
    };
    return { printTranslatedDescription };
  },
};
</script>
