export const dashboardSk = {
  dashboard: {
    title: "Nástěnka",
    noModules: "Nemáte přiřazeny žádné moduly nástěnky ke zobrazení",
    nrOfClients: "Počet klientů",
    errors: {
      cantGetDashboard: "Nepodařilo se stáhnout data pro nástěnku",
      cantGetDashboardModules:
        "Nepodařilo se stáhnout seznam modulů pro nástěnku",
    },
    form: {
      modules: "Povolené moduly",
    },
    widgets: {
      results: {
        title: "Výsledky",
        noRecords: "Momentálne nie sú k dispozícii žiadne výsledky.",
      },
      diagnostics: {
        title: "Diagnostika",
        noRecords: "Momentálne nie sú k dispozícii žiadne diagnostiky.",
      },
      games: {
        title: "Neurokognitivní trénink",
        noRecords: "Momentálne nie sú k dispozícii žiadne hry.",
        newGamesAvailable: "Jsou k dispozici nové hry",
        play: "Nová hra",
        last_gameplay: "Naposledy hráno",
        no_gameplay_record: "Tuto hru jste ještě nehráli",
      },
      training: {
        title: "Neurovizuální trenink",
        noRecords: "Momentálně není k dispozici žádný trénink.",
      },
      senaptec: {
        title: "Senaptec",
        measurements: "Výsledky merania",
        historyListTitle: "História merania",
      },
      senses_board: {
        title: "SensesBoard",
        measurements: "Výsledky merania",
        historyListTitle: "História merania",
      },
      "in-office": {
        title: "Vyšetrenie funkčnosti okohybných svalov",
        measurements: "Výsledky merania",
        historyListTitle: "História merania",
      },
      eye_flexibility: {
        title: "Flexibilita zraku",
        measurements: "Výsledky merania",
        historyListTitle: "História merania",
      },
      optics: {
        title: "MySenses - měření",
        measurements: "Výsledky merania",
        historyListTitle: "História merania",
      },
      eye_stability: {
        title: "Stabilita zraku",
        measurements: "Výsledky merania",
        historyListTitle: "História merania",
      },
    },
  },
};
