<template>
  <div class="col-12">
    <div v-if="record.data.description" v-html="record.data.description"></div>
    <div
      v-if="record.data.description_i18"
      v-html="printTranslatedDescription(record)"
    ></div>
  </div>
</template>
<script lang="ts">
import { UserCrmHistory } from "@/store/interfaces/CRM";
import { useCrm } from "@/composables/crm";

export default {
  name: "single-data-renderer",
  props: {
    record: {
      type: Object as () => UserCrmHistory,
      required: true,
      readonly: false,
    },
  },
  setup() {
    const { translateDescription } = useCrm();
    const printTranslatedDescription = (record: UserCrmHistory): string => {
      return translateDescription(record);
    };
    return { printTranslatedDescription };
  },
};
</script>
