export const programSk = {
  programs: {
    alerts: {
      diagnostics_alert: "Objednání na diagnostiku před koncem programu",
      program_beginning: "Upozornění na začátek programu",
    },
    tabs: {
      basic: "Základní nastavení",
      training: "Nastavení tréninku",
      alerts: "Upozornění",
    },
    toolTips: {
      edit: "Upraviť produkt {program}",
      delete: "Odstrániť produkt {program}",
      showEshopProduct: "Zobraziť naviazaný produkt eshopu",
    },
    noBasicTrainings: "Skupina nema navázané žádné základní produkty",
    editTitle: "Upraviť produkt",
    newTitle: "Nový produkt",
    addProgram: "Pridať produkt",
    errors: {
      notFound: "Produkt s ID {id} nebol nájdený",
      notYourRole: "Nemáte právo zobraziť produkt s ID {id}",
      nameExists: "Produkt s názvom `{name}` už existuje",
      commonGET_PROGRAM_BY_ID: "Chyba pri získavaní údajov o produkte",
      commonDELETE_PROGRAM_BY_ID: "Chyba pri odstraňovaní produktu",
      commonDELETE_PROGRAMS: "Nepodarilo sa odstrániť produkt",
      cantGetPrograms: "Chyba pri získávaní údajov o produktoch",
    },
    form: {
      name: "Názov",
      type: "Typ produktu",
      basicPrograms: "Navázané produkty",
      programGroups: "Nadřazený produkt",
      name_client: "Názov pre klienta",
      nrOfWeeks: "Dĺžka produktu",
      weeks: "týždňov",
      wcProduct: "Eshop produkt",
      nrOfPeriods: "Počet jednotek",
      invoice_line: "Název pro fakturaci",
      pricelist: {
        title: "Ceník",
        addItem: "Přidat položku",
        changeItem: "Upravit položku",
        period: "Jednotka",
        currency: "Měna",
        price: "Cena",
        unitPriceNotUnique: "Kombinace jednotka - měna musí být unikátní",
      },
      priceCalculator: {
        title: "Délka a cena produktu",
        period: "Jednotka",
        currency: "Měna",
        quantity: "Počet",
        noPricesAvaliable: "Tento produkt nemá zadáné žádné ceny",
        combinationNotFound: "Tato kombinace jednotky a měny není definována",
      },
      company: "Firma",
      specializations: "Specializácie",
      trainings: "Tréningy",
      trainingTemplates: "Šablony tréninku",
      accessories: "Pomůcky",
      programChanged: "Produkt bol upravený",
      programChangedFail: "Nepodarilo sa upraviť produkt",
      programAdded: "Nový produkt bol vytvorený",
      programAddFail: "Nepodarilo sa vytvoriť nový produkt",
    },
    types: {
      grouped: "Produktová skupina",
      basic: "Základní",
    },
    table: {
      header: {
        name: "Názov",
        type: "Typ produktu",
        basicPrograms: "Navázané produkty",
        programGroups: "Nadřazený produkt",
        name_client: "Názov pre klienta",
        nrOfWeeks: "Dĺžka produktu",
        company: "Firma",
        specializations: "Specializácie",
        actions: "Akcie",
      },

      deleteProgramQuestion:
        "Naozaj chcete odstrániť produkt <strong>{program}</strong>?",
      searchPrograms: "Hľadať produkty",
      programDeleted: "Produkt {program} bol odstránený",
      programsDeleted: "Produkty boli odstránené",
      noRecords: "Neboli nájdené žiadne produkty",
    },
  },
};
