import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import type {
  ClientWidgetData,
  ClientWidgetDataResponse,
  CompanyClientGroups,
  User,
} from "@/store/interfaces/User";
import { PaginationResponse } from "@/store/interfaces/Datatables";
import { SortingPair } from "@/store/interfaces/Datatables";
import {
  defaultDiagnosticSet,
  DiagnosticRecord,
} from "@/store/interfaces/DiagnosticRecord";
import _ from "lodash";
import { UserProgram } from "@/store/interfaces/Program";
import { Tool } from "@/store/interfaces/Tool";
import { UserGroup } from "@/store/interfaces/UserGroup";

@Module
export default class UserModule extends VuexModule {
  redirectTo = "";
  loadedUser: User = {} as User;
  users: User[] = [];
  clients: User[] = [];
  overseers: User[] = [];
  clientsList: User[] = [];
  clientsWithoutTraining: User[] = [];
  clientsWidgetData: ClientWidgetData = {} as ClientWidgetData;
  mappedByGroups: CompanyClientGroups[] = [];
  clientGroupsAvaliable: UserGroup[] = [];
  guests: User[] = [];
  specialists: User[] = [];
  pagination: PaginationResponse = {} as PaginationResponse;
  sorting: SortingPair = {} as SortingPair;
  clientsDataLoading = false;
  clientsListDataLoading = false;

  /**
   * Retreive current loaded specialists
   */
  get isClientsDataLoading(): boolean {
    return this.clientsDataLoading;
  }

  get getLoadedUser(): User {
    this.loadedUser.avatar = this.loadedUser.avatar
      ? this.loadedUser.avatar
      : "/media/avatars/blank.png";
    const user = {
      ...this.loadedUser,
      ...{ role: { ...this.loadedUser.role } },
      ...{ user_group: { ...this.loadedUser.user_group } },
      ...{ country: { ...this.loadedUser.country } },
      ...{
        diagnostics:
          this.loadedUser.diagnostics && this.loadedUser.diagnostics.length > 0
            ? this.loadedUser.diagnostics
            : [],
      },
      ...{
        assigned_clients:
          this.loadedUser.assigned_clients &&
          this.loadedUser.assigned_clients.length > 0
            ? this.loadedUser.assigned_clients
            : [],
      },
    };
    user.privacy = user.privacy ?? {
      gdpr: false,
      media: false,
    };
    user.anamnesis = user.anamnesis === null ? "" : user.anamnesis;

    return user;
  }

  /**
   * Retreive current loaded specialists
   */
  get getSpecialists(): User[] {
    return this.specialists;
  }

  /**
   * Retreive current loaded users
   */
  get getUsers(): User[] {
    return this.users;
  }

  /**
   * Retreive current loaded clients
   */
  get getClients(): User[] {
    return this.clients;
  }

  /**
   * Retreive current loaded clients
   */
  get getOverseers(): User[] {
    return this.overseers;
  }

  get getClientsList(): User[] {
    return this.clientsList;
  }

  /**
   * Retreive current loaded clients without trainings
   */
  get getClientsWidgetData(): ClientWidgetData | null {
    return this.clientsWidgetData;
  }
  get getClientsWithoutTraining(): User[] {
    return this.clientsWithoutTraining;
  }

  /**
   * Retreive current menu mapping for loaded clients
   */
  get getMappedByGroups(): CompanyClientGroups[] {
    return this.mappedByGroups;
  }

  get getClientGroupsAvaliable(): UserGroup[] {
    return this.clientGroupsAvaliable;
  }

  /**
   * Retreive current loaded clients
   */
  get getGuests(): User[] {
    return this.guests;
  }

  /**
   * Get last pagination setup
   */
  get getUsersPagination(): PaginationResponse {
    return this.pagination;
  }

  get getUsersSorting() {
    return this.sorting;
  }

  @Mutation
  [Mutations.SET_CLIENTS_DATA_LOADING](state: boolean) {
    this.clientsDataLoading = state;
  }

  @Mutation
  [Mutations.SET_CLIENTS_LIST_DATA_LOADING](state: boolean) {
    this.clientsListDataLoading = state;
  }

  @Mutation
  [Mutations.SET_LOADED_USER_AVATAR](avatar: string) {
    this.loadedUser.avatar = avatar;
  }

  @Mutation
  [Mutations.SET_LOADED_USER](user: User) {
    this.loadedUser = user;
  }

  @Mutation
  [Mutations.SET_USERS](data) {
    this.users = data.data;
  }

  @Mutation
  [Mutations.UPDATE_USERS](changedUser: User) {
    this.users = this.users.map((user: User) =>
      user.id === changedUser.id ? changedUser : user
    );
  }

  @Mutation
  [Mutations.SET_CLIENTS](data) {
    this.clients = data.data;
  }

  @Mutation
  [Mutations.SET_OVERSEERS](data) {
    this.overseers = data;
  }

  @Mutation
  [Mutations.SET_CLIENTS_LIST](data) {
    this.clientsList = data.data;
  }

  @Mutation
  [Mutations.SET_CLIENTS_WITHOUT_TRAINING](data) {
    this.clientsWithoutTraining = data.data;
  }

  @Mutation
  [Mutations.SET_CLIENTS_WIDGET_DATA](data) {
    for (const key in data) {
      this.clientsWidgetData[key] = data[key];
    }
  }

  @Mutation
  [Mutations.SET_MAPPED_BY_GROUPS](data) {
    this.mappedByGroups = data;
  }

  @Mutation
  [Mutations.SET_CLIENT_GROUP_AVALIABLE](data) {
    this.clientGroupsAvaliable = data;
  }

  @Mutation
  [Mutations.SET_GUESTS](data) {
    this.guests = data.data;
  }

  @Mutation
  [Mutations.SET_SPECIALISTS](data) {
    this.specialists = data.data;
  }

  @Mutation
  [Mutations.SET_USERS_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_USERS_SORTING](data) {
    this.sorting = data;
  }

  @Action
  [Actions.LOAD_USERS](payload) {
    ApiService.setHeader();
    return new Promise<User[]>((resolve, reject) => {
      ApiService.post("users", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_USERS, data.users);
            this.context.commit(
              Mutations.SET_USERS_PAGINATION,
              data.users.pagination
            );
            resolve(data.users.data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(Mutations.SET_ERROR, "users.errors.cantGetUsers");
          reject();
        });
    });
  }

  @Action
  [Actions.LOAD_CLIENTS](with_relations: string[] = []) {
    const user = this.context.getters["currentUser"];
    if (user.role_id == 3) {
      with_relations.push("tools");
    }
    ApiService.setHeader();
    const all_clients =
      !this.context.getters["shouldFilterClientsWithTraining"];
    return new Promise<User[]>((resolve, reject) => {
      ApiService.post("clients", { all_clients, with_relations })
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_CLIENTS, data.users);
            this.context.commit(
              Mutations.SET_MAPPED_BY_GROUPS,
              data.mappedByGroups
            );
            this.context.commit(
              Mutations.SET_CLIENT_GROUP_AVALIABLE,
              data.clientGroupsAvaliable
            );
            resolve(data.users.data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(Mutations.SET_ERROR, "users.errors.cantGetUsers");
          reject();
        });
    });
  }

  @Action
  [Actions.LOAD_OVERSEERS](payload) {
    ApiService.setHeader();
    return new Promise<User[]>((resolve, reject) => {
      ApiService.post("user/overseers", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_OVERSEERS, data.overseers);
            resolve(data.overseers);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "users.errors.cantGetOverseers"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.LOAD_CLIENTS_LIST]() {
    ApiService.setHeader();
    return new Promise<User[]>((resolve, reject) => {
      ApiService.get("clients-list")
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_CLIENTS_LIST, data.users);
            resolve(data.users.data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(Mutations.SET_ERROR, "users.errors.cantGetUsers");
          reject();
        });
    });
  }

  @Action
  [Actions.LOAD_CLIENTS_WITHOUT_TRAINING]() {
    ApiService.setHeader();
    return new Promise<User[]>((resolve, reject) => {
      ApiService.post("clients-without-training", {})
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_CLIENTS_WITHOUT_TRAINING,
              data.users
            );
            resolve(data.users.data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(Mutations.SET_ERROR, "users.errors.cantGetUsers");
          reject();
        });
    });
  }
  @Action
  [Actions.LOAD_CLIENTS_WIDGET_DATA](payload) {
    ApiService.setHeader();
    return new Promise<ClientWidgetData>((resolve, reject) => {
      ApiService.post("clients-widget-data", payload)
        .then(({ data }) => {
          if (data.success) {
            if (!payload.returnData) {
              this.context.commit(
                Mutations.SET_CLIENTS_WIDGET_DATA,
                data.widgets
              );
            }
            resolve(data.widgets);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(Mutations.SET_ERROR, "users.errors.cantGetUsers");
          reject();
        });
    });
  }

  @Action
  [Actions.LOAD_GUESTS]() {
    ApiService.setHeader();
    return new Promise<User[]>((resolve, reject) => {
      ApiService.post("guests", {})
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_GUESTS, data.users);
            resolve(data.users.data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "users.errors.cantGetGuests"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.LOAD_SPECIALISTS]() {
    ApiService.setHeader();
    return new Promise<User[]>((resolve, reject) => {
      ApiService.post("specialists", {})
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_SPECIALISTS, data.users);
            resolve(data.users.data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(Mutations.SET_ERROR, "users.errors.cantGetUsers");
          reject();
        });
    });
  }

  @Action
  [Actions.GET_USER_BY_ID](payload: number | object) {
    const id = typeof payload === "object" ? payload["id"] : payload;
    const mapDiagnosticRecords =
      typeof payload === "object"
        ? payload["mapDiagnosticRecords"] ?? true
        : true;
    const mergeGamesWithGroup =
      typeof payload === "object"
        ? payload["mergeGamesWithGroup"] ?? false
        : false;
    ApiService.setHeader();
    return new Promise<User>((resolve, reject) => {
      ApiService.get(`user/${id}`)
        .then(({ data }) => {
          if (data.success) {
            const user = data.user;
            const newDefaultSet = _.cloneDeep(defaultDiagnosticSet);
            user.diagnostic_records = user.diagnostic_records ?? [];
            if (mapDiagnosticRecords) {
              user.diagnostic_records = newDefaultSet.map((setItem) => {
                const userItem = user.diagnostic_records.filter(
                  (diagnosticRecord: DiagnosticRecord) => {
                    return diagnosticRecord.name === setItem.name;
                  }
                );
                if (userItem.length) {
                  return userItem[0];
                } else {
                  return setItem as DiagnosticRecord;
                }
              });
            }
            if (mergeGamesWithGroup) {
              user.tools = _.unionBy(
                user.tools.filter((tool: Tool) => {
                  return tool.type == "game";
                }),
                user.user_group.tools.filter((tool: Tool) => {
                  return tool.type == "game";
                }),
                "code"
              );
            }
            user.tools.map((tool: Tool) => {
              tool.settings = { ...tool.settings, ...tool.pivot.settings };
            });
            this.context.commit(Mutations.SET_LOADED_USER, user);

            resolve(user);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch((err) => {
          console.log(err);
          this.context.commit(
            Mutations.SET_ERROR,
            "users.errors.commonGET_USER_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.GET_USER_BY_ID_SIMPLE](id: number) {
    ApiService.setHeader();
    return new Promise<User>((resolve, reject) => {
      ApiService.get(`user-simple/${id}`)
        .then(({ data }) => {
          if (data.success) {
            const user = data.user;
            this.context.commit(Mutations.SET_LOADED_USER, user);
            resolve(user);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch((err) => {
          console.log(err);
          this.context.commit(
            Mutations.SET_ERROR,
            "users.errors.commonGET_USER_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.GET_USER_ACTIVE_PROGRAMS](id: number) {
    ApiService.setHeader();
    return new Promise<UserProgram[]>((resolve, reject) => {
      ApiService.get(`user/${id}/get-active-user-programs`)
        .then(({ data }) => {
          if (data.success) {
            resolve(data.user_programs);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch((err) => {
          console.log(err);
          this.context.commit(
            Mutations.SET_ERROR,
            "users.errors.cantGetActiveUserPrograms"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_USER_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<User>((resolve, reject) => {
      ApiService.delete(`user/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_USER, data.user);
            this.context.dispatch(Actions.LOAD_CLIENTS);
            this.context.dispatch(Actions.LOAD_GUESTS);
            resolve(data.user);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "users.errors.commonDELETE_USER_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_USERS](ids: []) {
    ApiService.setHeader();
    return new Promise<User>((resolve, reject) => {
      ApiService.post(`user/delete-users`, { ids })
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_USER, data.user);
            this.context.dispatch(Actions.LOAD_CLIENTS);
            this.context.dispatch(Actions.LOAD_GUESTS);
            resolve(data.deleted_ids);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "users.errors.commonDELETE_USERS"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_USER](user: User) {
    const payload = JSON.parse(JSON.stringify(user));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`user/${user.id}`, payload)
        .then(({ data }) => {
          if (data.success) {
            const user = data.user;
            const newDefaultSet = _.cloneDeep(defaultDiagnosticSet);
            user.diagnostic_records = user.diagnostic_records ?? [];
            user.diagnostic_records = newDefaultSet.map((setItem) => {
              const userItem = user.diagnostic_records.filter(
                (diagnosticRecord: DiagnosticRecord) => {
                  return diagnosticRecord.name === setItem.name;
                }
              );
              if (userItem.length) {
                return userItem[0];
              } else {
                return setItem as DiagnosticRecord;
              }
            });
            this.context.commit(Mutations.SET_LOADED_USER, data.user);
            if (this.context.getters["currentUser"].id == data.user.id) {
              this.context.commit(Mutations.SET_USER, data.user);
            }
            this.context.dispatch(Actions.LOAD_CLIENTS);
            this.context.dispatch(Actions.LOAD_GUESTS);
            resolve();
          } else {
            const i18string = data.i18;
            const i18params = { id: user.id, email: user.email };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "users.account.settings.form.userChangedFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_USER_NO_RELATIONS](user: User) {
    const payload = JSON.parse(JSON.stringify(user));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`user-no-rel/${user.id}`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.dispatch(Actions.LOAD_CLIENTS);
            this.context.dispatch(Actions.LOAD_GUESTS);
            resolve(data.user);
          } else {
            const i18string = data.i18;
            const i18params = { id: user.id, email: user.email };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "users.account.settings.form.userChangedFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_USER_PASSWORD](data) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`user/change-password/${data.user_id}`, data)
        .then(({ data }) => {
          if (data.success) {
            resolve();
          } else {
            const i18string = data.i18;
            const i18params = { id: data.user_id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "users.account.settings.signInMethod.password.passwordChangedFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPLOAD_AVATAR](data) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`user/upload-avatar/${data.user_id}`, data.formData)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_USER_AVATAR, data.file);
            resolve();
          } else {
            const i18string = data.i18;
            const i18params = { id: data.user_id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "users.errors.avatarUploadFailed"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.INVITE_USER](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("forgot-password", payload)
        .then(({ data }) => {
          resolve(data.user);
        })
        .catch(({ response }) => {
          if (response?.data) {
            this.context.commit(Mutations.SET_ERROR, response.data.i18);
          } else {
            this.context.commit(
              Mutations.SET_ERROR,
              "users.errors.commonINVITE_USER"
            );
          }
          reject();
        });
    });
  }

  @Action
  [Actions.CHECK_PRIVACY_CHANGE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("user-privacy-history/check-record", payload)
        .then(({ data }) => {
          resolve(data?.success);
        })
        .catch(({ response }) => {
          if (response?.data) {
            this.context.commit(Mutations.SET_ERROR, response.data.i18);
          } else {
            this.context.commit(
              Mutations.SET_ERROR,
              "users.errors.commonCHECK_PRIVACY_CHANGE"
            );
          }
          reject();
        });
    });
  }

  @Action
  [Actions.GDPR_CONSENT](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("user/gdpr-consent", payload)
        .then(({ data }) => {
          if (data?.success) {
            resolve();
          } else {
            this.context.commit(
              Mutations.SET_ERROR,
              "users.errors.commonGDPR_CONSENT"
            );
            reject();
          }
        })
        .catch(({ response }) => {
          if (response?.data) {
            this.context.commit(Mutations.SET_ERROR, response.data.i18);
          } else {
            this.context.commit(
              Mutations.SET_ERROR,
              "users.errors.commonGDPR_CONSENT"
            );
          }
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_USER_CONSENTS](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("user/update-consents", payload)
        .then(({ data }) => {
          if (data?.success) {
            resolve();
          } else {
            this.context.commit(
              Mutations.SET_ERROR,
              "users.errors.commonGDPR_CONSENT"
            );
            reject();
          }
        })
        .catch(({ response }) => {
          if (response?.data) {
            this.context.commit(Mutations.SET_ERROR, response.data.i18);
          } else {
            this.context.commit(
              Mutations.SET_ERROR,
              "users.errors.commonGDPR_CONSENT"
            );
          }
          reject();
        });
    });
  }

  @Action
  [Actions.GET_USER_DIAGNOSTIC_DATA_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<User>((resolve, reject) => {
      ApiService.get(`user/${id}/diagnostic-data`)
        .then(({ data }) => {
          if (data.success) {
            const user = data.user;
            const newDefaultSet = _.cloneDeep(defaultDiagnosticSet);
            user.diagnostic_records = user.diagnostic_records ?? [];
            user.diagnostic_records = newDefaultSet.map((setItem) => {
              const userItem = user.diagnostic_records.filter(
                (diagnosticRecord: DiagnosticRecord) => {
                  return diagnosticRecord.name === setItem.name;
                }
              );
              if (userItem.length) {
                return userItem[0];
              } else {
                return setItem as DiagnosticRecord;
              }
            });
            resolve(user);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch((err) => {
          console.log(err);
          this.context.commit(
            Mutations.SET_ERROR,
            "users.errors.commonGET_USER_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CALCULATE_POTENTIALS](payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("diagnostic/calculate-potentials", payload)
        .then(({ data }) => {
          if (data.success) {
            resolve(data.result);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "widgets.sensesBoardStats.errors.cantCalculatePotentials"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.GENERATE_USER_RESULTS_PDF](payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("results/generate-user-results-pdf", payload)
        .then(({ data }) => {
          if (data.success) {
            resolve(data.result);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "widgets.sensesBoardStats.errors.cantCalculatePotentials"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.GENERATE_SLAVOJ_FEE_PAID_CERTIFICATE](payload) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("slavoj/generate-slavoj-fee-paid-certificate", payload)
        .then(({ data }) => {
          if (data.success) {
            resolve(data.result);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "users.account.overview.form.failedToGeneratDoc"
          );
          reject();
        });
    });
  }
}
