import { UserCrmHistory } from "@/store/interfaces/CRM";
import { useTranslate } from "@/composables/translate";

export function useCrm() {
  const { translate } = useTranslate("");
  const translateDescription = (record: UserCrmHistory): string => {
    if (record.data?.description_i18) {
      if (record.data?.i18_parameters) {
        const params = {};
        for (const key in record.data?.i18_parameters) {
          if (record.data?.i18_parameters[key]) {
            if (record.data?.i18_parameters[key].startsWith("_i18_")) {
              params[key] = translate(
                record.data?.i18_parameters[key].replace("_i18_", "")
              );
            } else {
              params[key] = record.data?.i18_parameters[key];
            }
          }
        }
        return translate(record.data?.description_i18, undefined, params);
      }
      return translate(record.data?.description_i18, undefined);
    } else {
      return "";
    }
  };
  return { translateDescription };
}
