import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.record.data.description)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          innerHTML: $props.record.data.description
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    ($props.record.data.description_i18)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          innerHTML: $setup.printTranslatedDescription($props.record)
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}