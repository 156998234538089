import moment from "moment";
import {
  getCurrentDateFormat,
  getCurrentDateTimeFormat,
} from "@/core/plugins/i18n";

export const formatDateTime = (date: string) => {
  return moment(date).format(getCurrentDateTimeFormat());
};

export const formatDate = (date: string | null) => {
  return moment(date).format(getCurrentDateFormat());
};
