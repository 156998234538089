import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-1000px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = {
  class: "modal-header py-10 px-lg-17",
  id: "kt_modal_user_contact_event_header"
}
const _hoisted_5 = {
  key: 0,
  class: "d-flex align-items-center"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  class: "btn btn-sm btn-icon btn-active-color-primary",
  "data-bs-dismiss": "modal"
}
const _hoisted_8 = { class: "svg-icon svg-icon-1" }
const _hoisted_9 = { class: "modal-body py-10 px-lg-17" }
const _hoisted_10 = {
  class: "scroll-y me-n7 pe-7",
  id: "kt_modal_user_contact_event_scroll",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-max-height": "auto",
  "data-kt-scroll-dependencies": "#kt_modal_user_contact_event_header",
  "data-kt-scroll-wrappers": "#kt_modal_user_contact_event_scroll",
  "data-kt-scroll-offset": "300px"
}
const _hoisted_11 = { class: "mt-10" }
const _hoisted_12 = { class: "mt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_account_details = _resolveComponent("account-details")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_user_contact_event_form = _resolveComponent("user-contact-event-form")!
  const _component_crm_user_notifications = _resolveComponent("crm-user-notifications")!
  const _component_crm_user_history = _resolveComponent("crm-user-history")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", {
    class: "modal fade",
    ref: "userContactEventModalRef",
    id: 'user_contact_event_modal_' + _ctx.reason,
    tabindex: "-1",
    "aria-hidden": "true"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.display_account_header)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _withDirectives(_createVNode(_component_account_details, { non_interactive: true }, null, 512), [
                  [_directive_loading, _ctx.loadingUser]
                ])
              ]))
            : (_openBlock(), _createElementBlock("h2", _hoisted_6, _toDisplayString(_ctx.translate("title", undefined, {
                name: `${_ctx.user.name} ${_ctx.user.surname}`,
              })), 1)),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, [
              _createVNode(_component_inline_svg, { src: "/media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_user_contact_event_form, {
              reason: _ctx.reason,
              user: _ctx.user,
              onSave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleFormSubmit($event)))
            }, null, 8, ["reason", "user"]),
            _createElementVNode("h4", _hoisted_11, _toDisplayString(_ctx.translate("table.history", "notifications")), 1),
            _createVNode(_component_crm_user_notifications, { user: _ctx.user }, null, 8, ["user"]),
            _createElementVNode("h4", _hoisted_12, _toDisplayString(_ctx.translate("history.title", "crm")), 1),
            _createVNode(_component_crm_user_history, { user: _ctx.user }, null, 8, ["user"])
          ])
        ])
      ])
    ])
  ], 8, _hoisted_1))
}