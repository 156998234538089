export const accessoryCs = {
  accessories: {
    title: "Pomůcky",
    toolTips: {
      edit: "Upravit pomůcku {accessory}",
      delete: "Odstranit pomůcku {accessory}",
    },
    editTitle: "Upravit pomůcku",
    newTitle: "Nová pomůcka",
    addAccessory: "Přidat pomůcku",
    errors: {
      notFound: "Pomůcka id {id} nebyla nalezena",
      notYourRole: "Nemáte práva zobrazit pomůcku id {id}",
      nameExists: "Pomůcka s nazvem `{name}` již existuje",
      codeExists: "Pomůcka s kodem `{code}` již existuje",
      commonGET_ACCESSORY_BY_ID: "Chyba při získávání dat o pomůckách",
      commonDELETE_ACCESSORY_BY_ID: "Chyba při odstraňování pomůcky",
      commonDELETE_ACCESSORIES: "Nepodařilo se odstranit pomůcku",
      cantGetAccessories: "Chyba při získávání dat o pomůckách",
    },
    form: {
      addAccesory: "Přidat pomůcku",
      name: "Název",
      client_name: "Název pro klienta",
      color: "Barva",
      code: "Kód",
      description: "Popis",
      image: "Obrázek",
      icon: "Ikona",
      company: "Firmy",
      programs: "Produkty",
      accessoryChanged: "Pomůcka byla upravena",
      accessoryChangedFail: "Nepodařilo se upravit pomůcku",
      accessoryAdded: "Nová pomůcka byla vytvořena",
      accessoryAddFail: "Nepodařilo se vytvořit novou pomůcku",
    },
    table: {
      header: {
        name: "Název",
        icon: "Ikona",
        code: "Kód",
        programs: "Produkty",
        company: "Firmy",
        actions: "Akce",
      },
      deleteAccessoryQuestion:
        "Opravdu chcete odstranit pomůcku <strong>{accessory}</strong>?",
      deleteCheckInfo: "Pomůcka je použita v",
      deleteCheckTemplateCount: "šablonách cviků",
      deleteCheckActiveCount: "aktivních cvicích",
      searchAccessories: "Hledat pomůcku",
      accessoryDeleted: "Pomůcka {accessory} byla odstraněna",
      accessoriesDeleted: "Pomůcky byly odstraněny",
      noRecords: "Nebyly nalezeny žádné pomůcky",
    },
  },
};
