import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-5 mb-xl-10 bg-secondary-light" }
const _hoisted_2 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_clients_widgets_group = _resolveComponent("clients-widgets-group")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_clients_widgets_group)
      ])
    ])
  ]))
}