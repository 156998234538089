export const widgetSk = {
  widgets: {
    total: "Celkom",
    ClientsWithoutTraining: {
      title: "Klienti, ktorí nie sú v tréningu",
    },
    ClientsAfterTraining: {
      title: "Klienti po tréninku",
    },
    ClientsJustFinishedTraining: {
      title: "Klienti kteří právě ukončili trénink",
    },
    ClientsAfterDiagnostic: {
      title: "Klienti po diagnostike",
    },
    ClientsEndingProgram: {
      title: "Klienti s končiacim tréningom",
    },
    ClientsOrderDiagnostic: {
      title: "Klienti k objednaniu na diagnostiku",
    },
    ClientsLeads: {
      title: "Potenciálni klienti",
    },
    ClientsFirstWeekOfTraining: {
      title: "Klienti v prvom týždni tréningu",
    },
    ClientsTrainingRenewal: {
      title: "Obnovení tréninku",
    },
    ChatMessagesHistory: {
      title: "História správ",
      searchMessages: "Hľadať vo správach",
      tooltips: {
        openChat: "Otvoriť chat",
        markAsUnread: "Označiť ako neprečítané",
      },
      table: {
        read: "Prečítané",
        created_at: "Dátum",
      },
    },
    ClientsScheduledToContact: {
      title: "Klienti s plánovaným dátumom kontaktovania",
      scheduledOn: "Plánované na",
    },
    players: {
      title: "Zoznam hráčov",
    },
    inOfficeStats: {
      categories: {
        eye_muscles: "Okohybné svaly",
        flexibility: "Flexibilita",
        optics: "MySenses",
        flipper_right: "Pravé oko",
        flipper_left: "Ľavé oko",
        total: "Celkom",
      },
    },
    eyeFlexibilityStats: {
      categories: {
        eye_muscles: "Okohybné svaly",
        flexibility: "Flexibilita",
        flipper_right: "Pravé oko",
        flipper_left: "Ľavé oko",
        total: "Celkom",
      },
    },
    opticsStats: {
      categories: {
        refocus_both_eyes: "Rychlost zraku",
        eye_muscles: "Okohybné svaly",
        flexibility: "Flexibilita",
        flipper_right: "Pravé oko",
        flipper_left: "Ľavé oko",
        total: "Celkom",
      },
    },
    eyeStabilityStats: {
      categories: {
        eye_muscles: "Okohybné svaly",
        eye_muscles_far: "Svaly do diaľky",
        eye_muscles_close: "Svaly na blízko",
        total: "Stabilita zraku",
      },
    },
    coach: {
      historyListTitle: "Merania",
      clickForDetail: "Detail výsledku je k dispozícii po rozkliknutí.",
      clientSettings: "Nastavení grafu",
      invalidDataMessage:
        "Vyzerá to, že namerané údaje silne vybočujú z hodnôt, ktorých je možné dosiahnuť správnym meraním.",
      comparisionWithPreviousTranining: "porovnání s přechozím měřením",
      currentValue: "Aktuální měření",
      previousValue: "Předchozí měření",
      initialValue: "Vstupní měření",
      diagnosticTitles: {
        senses_board: "SensesBoard",
        in_office: "Okohybné svaly",
        eye_flexibility: "Flexibilita zraku",
        optics: "MySenses - měření",
        eye_stability: "Stabilita zraku",
        total: "Celkom",
      },
      results: "Výsledky",
      games: "Hry",
      radarTitle: "Porovnávací graf hráčů",
      radarComputedValues: {
        __average: "Průměrný graf týmu",
      },
      export: {
        title: "Export dat z isenses",
        button: "Vygenerovat report",
        pdfGenerated: "Report byl vygenerován",
        graphTitles: {
          total: "Porovnávací graf hráčů",
          average: "Průměrný graf týmu",
        },
      },
      gamesForClient: "Hry pro klienta",
      backToListOfClients: "Zpět na výběr klientů",
      group: "Skupina",
      filter: "Filter",
      individuals: "Jednotlivci",
      noPlayers: "Nie sú k dispozícii žiadne údaje",
      noDiagnostics: "Nie sú k dispozícii žiadne výsledky diagnostiky",
      form: {
        userGroup: "Skupina",
        players: "Hráči",
      },
      errors: {
        cantGetRadarData: "Nepodařilo se stáhnout data pro radarový graf",
      },
    },
    sensesBoardStats: {
      title: "Výsledky SensesBoard",
      historyListTitle: "Merania",
      invalidDataMessage:
        "Vyzerá to, že namerané údaje silne vybočujú z hodnôt, ktorých je možné dosiahnuť správnym meraním.",
      SBPotential: {
        potential: "Potenciál",
        graph: {
          title: "Potenciál rozvoja",
          players: "Hráči",
          groups: "Skupiny",
          categories: {
            game_reading: "Pochopenie situácie",
            orientation_in_space: "Orientácia v priestore",
            refocus_both_eyes: "Rýchlosť zraku",
            coordination: "Presnosť zraku",
            understanding_situation: "Neurovision",
            total: "Celkom",
          },
        },
        modal: {
          title: "Potenciál jednotlivca",
          titleClient: "SensesBoard - Váš potenciál",
          performance: "Výkon",
          value: "Hodnota",
          recommendation: "Odporúčanie",
          potential: "Potenciál",
          graphs: {
            game_reading: {
              title: "Pochopenie situácie",
            },
            orientation_in_space: {
              title: "Orientácia v priestore",
            },
            refocus_both_eyes: {
              title: "Rýchlosť zraku",
            },
            coordination: {
              title: "Presnosť zraku",
            },
            understanding_situation: {
              title: "Neurovision",
            },
            total: {
              title: "Neurovision",
            },
          },
        },
      },
      SBNormativeIndex: {
        title: "Normatívny index",
        total: "Celkový",
        rank: "Poradie",
        name: "Meno",
        index: "Index",
        group: "Skupina",
        legend: {
          aboveAverage: "Nadpriemer",
          average: "Priemer",
          belowAverage: "Podpriemer",
        },
      },
      errors: {
        cantSensesBoardStats:
          "Nepodarilo sa stiahnuť štatistické údaje SensesBoard",
        cantExportSensesBoardStats:
          "Nepodarilo sa exportovať štatistické údaje SensesBoard",
        cantLoadNiLegend:
          "Nepodarilo sa stiahnuť pomocné údaje pre SensesBoard",
      },
    },
  },
};
