import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-6 d-none" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-6" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "card-body pt-0" }
const _hoisted_8 = {
  key: 1,
  class: "alert alert-dismissible d-flex flex-column flex-sm-row w-100 p-5 mb-10"
}
const _hoisted_9 = { class: "svg-icon svg-icon-2hx svg-icon-info me-4" }
const _hoisted_10 = { class: "d-flex align-items-center" }
const _hoisted_11 = { class: "mb-0 text-inverse-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_i18n_d = _resolveComponent("i18n-d")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen021.svg" })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
            class: "form-control form-control-solid w-250px ps-15",
            placeholder: _ctx.translate('.searchRecords')
          }, null, 40, _hoisted_6), [
            [_vModelText, _ctx.search]
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.history.length)
        ? (_openBlock(), _createBlock(_component_Datatable, {
            key: 0,
            "sort-label": _ctx.sorting.columnName,
            order: _ctx.sorting.order,
            "table-header": _ctx.header,
            "row-class-callback": 
          (row) => {
            return 'activity-' + row.activity;
          }
        ,
            loading: _ctx.loading,
            "table-data": _ctx.history,
            "empty-table-text": '',
            total: _ctx.pagination.total,
            "rows-per-page": _ctx.pagination.per_page,
            onCurrentChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.doPageChange($event))),
            onSort: _cache[3] || (_cache[3] = ($event: any) => (_ctx.doSort($event))),
            onItemsPerPageChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.doItemsPerPageChange($event)))
          }, {
            "cell-created_at": _withCtx(({ row: record }) => [
              _createVNode(_component_i18n_d, {
                value: _ctx.getDate(record.created_at)
              }, null, 8, ["value"]),
              (record.created_by)
                ? (_openBlock(), _createBlock(_component_el_tooltip, {
                    key: 0,
                    content: 
              record.created_by?.name + ' ' + record.created_by?.surname
            
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createElementVNode("i", { class: "ms-3 fa fa-user" }, null, -1)
                    ])),
                    _: 2
                  }, 1032, ["content"]))
                : _createCommentVNode("", true),
              _createElementVNode("i", null, "  " + _toDisplayString(record.created_by?.initials), 1)
            ]),
            "cell-activity": _withCtx(({ row: record }) => [
              _createTextVNode(_toDisplayString(_ctx.translate("activities." + record.activity)), 1)
            ]),
            "cell-data": _withCtx(({ row: record }) => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getRendererName(record)), { record: record }, null, 8, ["record"]))
            ]),
            _: 1
          }, 8, ["sort-label", "order", "table-header", "row-class-callback", "loading", "table-data", "total", "rows-per-page"]))
        : _createCommentVNode("", true),
      (!_ctx.loading && _ctx.history.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("span", _hoisted_9, [
              _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen044.svg" })
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("h6", _hoisted_11, _toDisplayString(_ctx.translate(".noRecords")), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}