export const langEn = {
  leads: {
    import: {
      leadsImportBatchSuccess: "Import was successful",
    },
  },
  privacy: {
    consentsChanged: "Privacy settings change",
    consentsChangedText:
      "There have been changes to the privacy settings that require your attention. Please check out the following consents:",
    i_agree_with: "I agree with",
    i_agree: "I agree",
    gdpr_title: "Terms and conditions",
    gdpr_label: "terms and conditions",
    gdpr: "<p>By confirming your consent when registering in the iSenses portal of TP Optometry s.r.o (ID 08044759) with its registered office at Olšany 51, Olšany 683 01, Czech Republic, email: tomas{'@'}tpoptometry.cz www.tpoptometry.cz that manages GDPR consents for companies:</p><p>SportSenses s.r.o. (ID 08429138 dič CZ08429138 ) with its registered office at Olšany 51, Olšany 683 01, Czech Republic</p><p>and</p><p>MySenses s.r.o (ID 08429588) with its registered office at Olšany 51, Olšany 683 01, Czech Republic</p><p>I give my consent to the processing of my personal data in accordance with Article 13 of Regulation (EU) 2016/679 of the European Parliament and of the Council of 27.04.2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation \"GDPR\").</p><p>You have access to consents about the data we process and store through your account in the iSenses app/portal, where you can exercise the right to portability, rectification, erasure or restriction of the processing of personal data. You can find this option in the GDPR tab. If you have any doubts, please contact us at my{'@'}senses.zone.</p><p>Our goal is to provide our services to clients always with the best knowledge and conscience. If you do not like something, please contact tomas{'@'}tpoptometry.cz and we will solve everything together.</p> <p>Sincerely,</p><p>Tomáš Pospíchal</p><p>Managing Director</p> ",
    gdprMissing:
      "You must agree with terms and conditions in order to continue using isenses",
    gdprAgreed: "Thank you. Now you can continue to isenses",
    media_title: "Consent to publication of my image",
    media_label: "publication of my image",
    media:
      "I consent to the publication of my likeness in photographs and video footage for the purposes of marketing, promotion on the website, social media, in the annual report and other promotional materials",
    research_title:
      "Consent to the use of anonymised measurement data for graphing and study purposes.",
    research_label:
      "using anonymised measurement data for graphing and study purposes.",
    research:
      "I consent to the use of fully anonymised results from diagnostic methods and training programmes for the purposes of graphing and study.",
    research_badge_label: "Research",
  },
  currencies: {
    CZK: "CZK",
    EUR: "EUR",
  },
  common: {
    discard: "Discard",
    saveChanges: "Save Changes",
    cancelChanges: "Discard Changes",
    save: "Save",
    saveChangesAndRefresh: "Uložit and regenerate",
    saveAsTemplate: "Save as template",
    pause: "Pause",
    continue: "Continue",
    cancel: "Cancel",
    back: "Back",
    confirm: "Confirm",
    yes: "Yes",
    no: "No",
    problems: "Problem",
    warning: "Warning",
    noProblems: "Ok",
    pickADate: "Pick a date",
    pleaseWait: "Please wait...",
    okGotIt: "Ok, got it!",
    tryAgain: "Try again!",
    unauthorized: "You are not authorized to watch this page",
    returnHome: "Return Home",
    modal: {
      picker: {
        all: "All",
        trainings: {
          title: "Pick a training",
          filters: {
            company: "Company",
            language: "Language",
            specialization: "Specialization",
          },
        },
        programs: {
          title: "Pick a product",
          filters: {
            company: "Company",
            specialization: "Specialization",
          },
        },
      },
    },
  },
  dayOfWeeks: {
    mon: "Monday",
    tue: "Tuesday",
    wed: "Wednesday",
    thu: "Thursday",
    fri: "Friday",
    sat: "Saturday",
    sun: "Sunday",
  },
  jwt: {
    invalidToken: "Login session expired. You are logged out",
  },
  validator: {
    image: {
      notImage: "The file you provided is not an image.",
      tooBig: "The file you provided is too big.",
    },
    file: {
      tooBig: "The file you provided is too big.",
    },
    required: {
      email: "Email is a required field",
      invEmail: "Invoice email is a required field",
      userGroupName: "Name of the group is a required field",
      userGroup: "You must select a user group",
      specializationName: "Name of the specialization is a required field",
      trainingName: "Name of the training is a required field",
      companyName: "Name of the company is a required field",
      supplierName: "Name of the supplier is a required field",
      customerName: "NName of the customer is a required field",
      role: "User role is a required field",
      programName: "Name of the product is a required field",
      sportName: "Name of the sport is a required field",
      accessoryName: "Name of the accessory is a required field",
      accessoryCode: "Code of the accessory is a required field",
      toolCode: "Code of the tool is a required field",
      accessoryImage: "You must select a accessory image",
      accessoryIcon: "You must select a accessory icon",
      exercise: "You must select a exercise",
      unit_type: "You must select a unit type",
      total_uses: "You must enter total uses value. >= 0 ",
      counting_type: "You must select a counting type",
      value: "You must set a value",
      unit: "You must select a unit",
      exerciseName: "Name of the exercise is a required field",
      exerciseCode: "Code of the exercise is a required field",
      exerciseImage: "You must select a exercise image",
      exerciseIcon: "You must select a exercise icon",
      exerciseVideo: "You must select a exercise video",
      exerciseCategoryName: "Name of the exercise category is a required field",
      trainingTemplateName: "Name of the training template is a required field",
      exerciseCategoryIcon: "You must select a exercise category icon",
      company: "You must select a company",
      companyType: "You must select a type company / organization",
      bodyPart: "You must select a body part",
      birthDay: "You must select a year of birth",
      account: "Account nr. is required field",
      bank_name: "Bank name is required field",
      currency: "You must select a currency",
      in: "Company registration Id is a required field",
      street: "Street is a required field",
      city: "City is a required field",
      zip: "Zipcode is a required field",
      country: "You must select a country",
      language: "You must select a language",
      fileName: "File name is a required field",
      token: "Token is a required field",
      user_group: "You must select a user group",
      nrOfWeeks: "You must enter number of weeks",
      phone: "Phone is a required field",
      password: "Password is a required field",
      passwordConfirmation: "Password confirmation is a required field",
      firstName: "First name is a required field",
      lastName: "Last name is a required field",
      toc: "You must agree with terms and conditions",
      specialization: "You must select specialization",
      relationship_legal_representative:
        "Legal representative relationship is a required field",
      name_legal_representative:
        "Legal representative name is a required field",
      email_legal_representative:
        "Legal representative email is a required field",
      phone_legal_representative:
        "Legal representative phone is a required field",
      // invoices
      productName: "Product name name is a required field",
      invoiceNumber: "Invoice number is a required field",
      variable_symbol: "Variable symbol is a required field",
      price: "You must enter price with vat",
      issueDate: "You must select a issue date",
      paymentDate: "You must select a date of payment",
      taxDate: "You must select a date of taxable supply",
      companyBankName: "You must enter bank name",
      companyBankAccount: "You must enter account number",
      quantity: "You must enter quantity",
      client: "You must select a client",
    },
    unique: {
      email: "Email has already been taken",
      userGroupName: "User group with this name already exists",
      specializationName: "Specialization with this name already exists",
    },
    match: {
      password: "Passwords must match",
    },
    format: {
      email: "You must provide a valid email",
      phone: "You must provide a valid phone number",
    },
    length: {
      min: {
        1: {
          nrOfWeeks: "Minimal number of week is 1",
        },
        4: {
          password: "Password must be at least 4 characters",
        },
        6: {
          password: "Password must be at least 6 characters",
        },
        9: {
          phone: "Phone must be at least 9 characters",
        },
      },
      max: {
        52: {
          nrOfWeeks: "Maximal number of week is 52",
        },
      },
    },
  },
  auth: {
    passwordChange: {
      passwordChanged: "Password changed.You can sign in.",
      passwords_token:
        "Password reset token is invalid. Please make new reset request.",
      serverError: "Failed to cahnge password. Please try again later.",
      title: "Password change",
      subtitle: "Enter a new password.",
      form: {
        password: "Password",
        confirmPassword: "Confirm Password",
        submit: "Submit",
        cancel: "Cancel",
        pleaseWait: "Please wait...",
      },
      okGotIt: "Ok, got it!",
      tryAgain: "Try again!",
    },
    passwordReset: {
      mailSent: "Password reset link sent. Check your email.",
      passwords_throttled: "Password reset link was already sent",
      passwords_user: "Email not found",
      serverError: "Failed to send email. Please try again later.",
      okGotIt: "Ok, got it!",
      tryAgain: "Try again!",
    },
    invalidCredentials: "Invalid credentials",
    couldNotCreateToken: "Could not create token",
    logOutSuccess: "User was looged out",
    logOutFail: "Failed to logout user",
    signIn: {
      title: "Sign In to iSenses",
      question: "New Here?",
      createAnAccount: "Create an Account",
      form: {
        email: "Email",
        password: "Password",
        forgotPassword: "Forgot Password ?",
        continue: "Continue",
        pleaseWait: "Please wait...",
        allIsCool: "All is cool! Now you submit this form",
        okGotIt: "Ok, got it!",
        tryAgain: "Try again!",
      },
    },
    signUp: {
      title: "Create an Account",
      question: "Already have an account?",
      signIn: "Sign in here",
      form: {
        firstName: "First name",
        lastName: "Last name",
        email: "Email",
        phone: "Phone",
        birthDate: "Year of birth",
        legal_representative: "Legal representative",
        relationship_legal_representative: "Relationship",
        name_legal_representative: "Full name",
        email_legal_representative: "Email",
        phone_legal_representative: "Phone",
        pickAYear: "Pick a year",
        password: "Password",
        confirmPassword: "Confirm Password",
        language: "Language",
        submit: "Submit",
        iAgree: "I Agree &",
        termsAndConditions: "Terms and conditions",
        pleaseWait: "Please wait...",
        allIsCool: "All is cool! Now you submit this form",
        okGotIt: "Ok, got it!",
        tryAgain: "Try again!",
      },
    },
    forgotPassword: {
      title: "Forgot Password ?",
      subtitle: "Enter your email to reset your password.",
      form: {
        email: "Email",
        submit: "Submit",
        cancel: "Cancel",
        pleaseWait: "Please wait...",
      },
    },
  },
  "top-bar": {
    clientCard: "client card",
    coordinator: "coordinator",
  },

  menu: {
    filter: {
      all: "All",
      in_training: "In training",
    },
    dashboard: "Dashboard",
    dashboardResults: "Results",
    dashboardDiagnostics: "Diagnostics",
    dashboardGames: "Neurocognitive training",
    dashboardTraining: "Neurovisual training",
    dashboardTrainingExercise: "Exercise detail",
    clients: "Clients",
    players: "Players",
    guests: "Guests",
    users: "Users",
    usersList: "Users list",
    addUser: "Add user",
    addClient: "Add client",
    userGroups: "User groups",
    addUserGroup: "Add user group",
    userGroup: "Edit user group",
    specializations: "Specializations",
    addSpecialization: "Add specialization",
    specialization: "Edit specialization",
    files: "Files library",
    addFile: "Add file",
    finance: "Finance",
    file: "Edit file",
    trainings: "Trainings",
    trainings10: "Training 1.0",
    addTraining: "Add training",
    training: "Edit training",
    account: "Account",
    overview: "Overview",
    registrationTokens: "Registartion tokens",
    companyBankAccounts: "Bank accounts",
    settings: "Settings",
    assignment: "Assignment",
    assignedClients: "Assigned Clients",
    results: "Results",
    otherContacts: "Other contacts",
    accountTraining: "Training",
    diagnostics: "Diagnostics",
    dev: "DEV",
    game: "Game",
    languageManager: "Language manager",
    tools: "Tools",
    addTool: "Add tool",
    tool: "Edit tool",
    payments: "Payments",
    addPayment: "Add payment",
    payment: "Edit payment",
    bulkMessage: "Bulk message",
    importDiagnostics: "Diag. data",
    exportDiagnostics: "Diag. data",
    importLeads: "Leads",
    companies: "Companies",
    addCompany: "Add company",
    company: "Edit company",
    programs: "Products",
    accountPrograms: "Programs",
    addProgram: "Add product",
    program: "Edit product",
    accountPrivacy: "Privacy settings",
    privacy: "Privacy",
    notifications: "Notifications",
    crm: "CRM",
    accountNotifications: "Notifications",
    privacyShort: "Privacy",
    accountChat: "Chat",
    chat: "Chat",
    sports: "Sports",
    addSport: "Add sport",
    sport: "Edit sport",
    invoices: "Issued invoices",
    addInvoice: "Add invoice",
    cloneInvoice: "Clone invoice",
    addInvoiceForUser: "Add invoice for client",
    invoice: "Edit invoice",
    playground: "Playground",
    "dev-games": "Games",
    accessories: "Accessories",
    accountAccessories: "Accessories",
    accountTools: "Tools",
    addAccessory: "Add accessory",
    accessory: "Edit accessory",
    bodyParts: "Body parts",
    addBodyPart: "Add body part",
    bodyPart: "Edit body part",
    exerciseExecutions: "Exercise executions",
    addExerciseExecution: "Add exercise execution",
    exerciseExecution: "Edit exercise execution",
    training20: "Training 2.0",
    trainingTemplatePeriods: "Exercises sets",
    templatePeriodExercises: "Exercise templates",
    trainingTemplates: "Training templates",
    addTrainingTemplate: "Add training template",
    trainingTemplate: "Edit training template",
    units: "Units",
    addUnit: "Add unit",
    unit: "Edit unit",
    exercises: "Exercises",
    addExercise: "Add exercise",
    exercise: "Edit exercise",
    exerciseCategories: "Exercise categories",
    addExerciseCategory: "Add exercise category",
    exerciseCategory: "Edit exercise category",
    diagnostics_management: "Diagnostics",
    addDiagnostic: "Add diagnostic",
    dashboardClientGames: "Client games",
    diagnostic: "Edit diagnostic",
  },
  titles: {
    "gdpr-consent": "Terms and conditions consent",
    "consents-change": "Privacy settings change",
    "sign-in": "Sign in",
    "sign-up": "sign up",
    "password-reset": "Password reset",
    "password-change": "Password change",
    dashboard: "Dashboard",
    companyDashboard: "Company dashboard",
    groupDashboard: "Group dashboard",
    dashboardResults: "Results",
    dashboardClientGames: "Client games",
    dashboardDiagnostics: "Diagnostics",
    dashboardTrainingExercise: "Exercise detail",
    dashboardGames: "Neurocognitive training",
    dashboardTraining: "Neurovisual training",
    "dashboard-new": "Dashboard(IN-DEV)",
    clients: "Clients",
    guests: "Guests",
    users: "Users",
    addUser: "Add user",
    userGroups: "User groups",
    addUserGroup: "Add user group",
    userGroup: "Edit user group",
    specializations: "Specializations",
    addSpecialization: "Add specialization",
    specialization: "Edit specialization",
    files: "Files library",
    addFile: "Add file",
    file: "Edit file",
    registrationTokens: "Registartion tokens",
    registrationToken: "Edit registartion token",
    addRegistrationToken: "Add registartion token",
    companyBankAccounts: "Bank accounts",
    companyBankAccount: "Edit bank account",
    addCompanyBankAccount: "Add Bank account",
    trainings: "Trainings",
    addTraining: "Add training",
    training: "Edit training",
    account: "Account",
    accountPrograms: "Programs",
    overview: "Overview",
    settings: "Settings",
    assignment: "Assignment",
    assignedClients: "Assigned Clients",
    results: "Results",
    otherContacts: "Other contacts",
    accountTraining: "Training",
    diagnostics: "Diagnostics",
    languageManager: "Language manager",
    dev: "DEV",
    game: "Game",
    "dev-games": "Games",
    bulkMessage: "Bulk message",
    importDiagnostics: "Diag. data import",
    exportDiagnostics: "Diag. data export",
    importLeads: "Import leads",
    "import-diagnostics": "Diag. data import",
    "export-diagnostics": "Diag. data export",
    "import-leads": "Import leads",
    "bulk-message": "Bulk message",
    companies: "Companies",
    addCompany: "Add company",
    company: "Edit company",
    programs: "Products",
    addProgram: "Add product",
    program: "Edit product",
    privacy: "Privacy",
    notifications: "Notifications",
    crm: "CRM",
    chat: "Chat",
    sports: "Sports",
    addSport: "Add sport",
    sport: "Edit sport",
    invoices: "Issued invoices",
    addInvoice: "Add invoice",
    cloneInvoice: "Clone invoice",
    addInvoiceForUser: "Add invoice for client",
    invoice: "Edit invoice",
    accessories: "Accessories",
    accountAccessories: "Accessories",
    accountDashboard: "User dashboard",
    accountTools: "Tools",
    addAccessory: "Add accessory",
    accessory: "Edit accessory",
    tools: "Tools",
    addTool: "Add tool",
    tool: "Edit tool",
    payments: "Payments",
    addPayment: "Add payment",
    payment: "Edit payment",
    bodyParts: "Body parts",
    addBodyPart: "Add body part",
    playground: "Playground",
    bodyPart: "Edit body part",
    units: "Units",
    addUnit: "Add unit",
    unit: "Edit unit",
    exercises: "Exercises",
    addExercise: "Add exercise",
    exercise: "Edit exercise",
    cloneExercise: "Clone exercise",
    cloneTemplatePeriodExercise: "Clone exercise",
    exerciseCategories: "Exercise categories",
    addExerciseCategory: "Add exercise category",
    exerciseCategory: "Edit exercise category",
    exerciseExecutions: "Exercise executions",
    addExerciseExecution: "Add exercise execution",
    exerciseExecution: "Edit exercise execution",
    training20: "Training 2.0",
    trainingTemplatePeriods: "Exercises sets",
    addTrainingTemplatePeriod: "Add exercise set",
    trainingTemplatePeriod: "Edit exercise set",
    templatePeriodExercises: "Exercise templates",
    addTemplatePeriodExercise: "Add exercise template",
    addTemplatePeriodExerciseFrom: "Add exercise template",
    templatePeriodExercise: "Edit exercise template",
    trainingTemplates: "Training templates",
    addTrainingTemplate: "Add training template",
    trainingTemplate: "Edit training template",
    diagnostics_management: "Diagnostics",
    addDiagnostic: "Add diagnostic",
    diagnostic: "Edit diagnostic",
    "404": "Not found",
    "403": "Unauthorized",
  },
  header: {
    topbar: {
      userDropDown: {
        signOut: "Sign Out",
        language: "Language",
        myProfile: "My Profile",
      },
    },
  },
};
