import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import type {
  RegistrationToken,
  RegistrationTokenEvent,
} from "@/store/interfaces/RegistrationToken";
import { PaginationResponse, SortingPair } from "@/store/interfaces/Datatables";
import ApiService from "@/core/services/ApiService";

@Module
export default class RegistrationTokenModule extends VuexModule {
  redirectTo = "";
  loadedRegistrationToken: RegistrationToken = {} as RegistrationToken;
  RegistrationTokens: RegistrationToken[] = [];
  pagination: PaginationResponse = {} as PaginationResponse;
  sorting: SortingPair = {} as SortingPair;

  get getLoadedRegistrationToken(): RegistrationToken {
    return {
      ...this.loadedRegistrationToken,
      ...{
        events: this.loadedRegistrationToken.events
          ? this.loadedRegistrationToken.events
          : [],
      },
    } as RegistrationToken;
  }

  /**
   * Retreive current loaded registrationTokens
   */
  get getRegistrationTokens(): RegistrationToken[] {
    return this.RegistrationTokens;
  }

  /**
   * Get last pagination setup
   */
  get getRegistrationTokensPagination(): PaginationResponse {
    return this.pagination;
  }

  get getRegistrationTokensSorting() {
    return this.sorting;
  }

  @Mutation
  [Mutations.SET_LOADED_REGISTRATION_TOKEN](
    RegistrationToken: RegistrationToken
  ) {
    this.loadedRegistrationToken = RegistrationToken;
  }

  @Mutation
  [Mutations.SET_REGISTRATION_TOKENS](data) {
    this.RegistrationTokens = data.data;
  }

  @Mutation
  [Mutations.SET_REGISTRATION_TOKENS_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_REGISTRATION_TOKENS_SORTING](data) {
    this.sorting = data;
  }

  @Action
  [Actions.LOAD_REGISTRATION_TOKENS](payload) {
    ApiService.setHeader();
    return new Promise<RegistrationToken[]>((resolve, reject) => {
      ApiService.post("registrationTokens", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_REGISTRATION_TOKENS,
              data["registrationTokens"]
            );
            this.context.commit(
              Mutations.SET_REGISTRATION_TOKENS_PAGINATION,
              data["registrationTokens"].pagination
            );
            resolve(data["registrationTokens"].data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "registrationTokens.errors.cantGetRegistrationTokens"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.GET_REGISTRATION_TOKEN_BY_ID](id: number) {
    ApiService.setHeader();
    if (id == undefined) {
      return new Promise<RegistrationToken>((resolve) => {
        const RegistrationToken = {} as RegistrationToken;
        this.context.commit(
          Mutations.SET_LOADED_REGISTRATION_TOKEN,
          RegistrationToken
        );
        resolve(RegistrationToken);
      });
    }
    return new Promise<RegistrationToken>((resolve, reject) => {
      ApiService.get(`registrationToken/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_REGISTRATION_TOKEN,
              data["registrationToken"]
            );
            resolve(data["registrationToken"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "registrationTokens.errors.commonGET_REGISTRATION_TOKEN_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_REGISTRATION_TOKEN_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<RegistrationToken>((resolve, reject) => {
      ApiService.delete(`registrationToken/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_REGISTRATION_TOKEN,
              data["registrationToken"]
            );
            resolve(data["registrationToken"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "registrationTokens.errors.commonDELETE_REGISTRATION_TOKEN_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_REGISTRATION_TOKEN](RegistrationToken: RegistrationToken) {
    const payload = JSON.parse(JSON.stringify(RegistrationToken));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`registrationToken/${RegistrationToken.id}`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_REGISTRATION_TOKEN,
              data["registrationToken"]
            );
            resolve();
          } else {
            const i18string = data.i18;
            const i18params = {
              id: RegistrationToken.id,
              token: RegistrationToken.token,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "registrationTokens.form.registrationTokenChangedFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_REGISTRATION_TOKEN](RegistrationToken: RegistrationToken) {
    const payload = JSON.parse(JSON.stringify(RegistrationToken));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`registrationToken`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_REGISTRATION_TOKEN,
              data["registrationToken"]
            );
            resolve(data["registrationToken"]);
          } else {
            const i18string = data.i18;
            const i18params = {
              id: RegistrationToken.id,
              token: RegistrationToken.token,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "registrationTokens.form.registrationTokenAddFail"
          );
          reject();
        });
    });
  }
  get getRegistrationTokenEvents(): RegistrationTokenEvent[] {
    return [
      { action: "S2boardEnable", values: [] },
      { action: "InOfficeEnable", values: [] },
      { action: "EyeStabilityEnable", values: [] },
      {
        action: "EyeFlexibilityEnable",
        values: [],
      },
      {
        action: "OpticsEnable",
        values: [],
      },
    ];
  }
}
