<template>
  <div v-if="record.data.description">{{ record.data.description }}</div>
</template>
<script lang="ts">
import { UserCrmHistory } from "@/store/interfaces/CRM";

export default {
  name: "with-image-data-renderer",
  props: {
    record: {
      type: Object as () => UserCrmHistory,
      required: true,
      readonly: false,
    },
  },
};
</script>
