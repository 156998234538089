<template>
  <div class="card mb-5 mb-xl-10 bg-secondary-light">
    <!--begin::Card header-->
    <!--begin::Content-->
    <div>
      <div class="card-body">
        <clients-widgets-group></clients-widgets-group>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ClientsWidgetsGroup from "@/components/widgets/isenses/ClientsWidgetsGroup.vue";

export default defineComponent({
  name: "events-dashboard-module",
  components: { ClientsWidgetsGroup },
  setup() {
    return {};
  },
});
</script>
