import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex flex-wrap flex-sm-nowrap mb-3" }
const _hoisted_2 = { class: "me-7 mb-4" }
const _hoisted_3 = { class: "symbol symbol-100px symbol-lg-160px symbol-fixed position-relative" }
const _hoisted_4 = {
  key: 0,
  class: "position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "svg-icon svg-icon-2x" }
const _hoisted_7 = { class: "svg-icon svg-icon-2x" }
const _hoisted_8 = { class: "svg-icon svg-icon-2x" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "text-gray-400 me-2" }
const _hoisted_11 = { class: "text-gray-400 me-2" }
const _hoisted_12 = { class: "text-gray-400 me-2" }
const _hoisted_13 = { class: "flex-grow-1" }
const _hoisted_14 = { class: "d-flex justify-content-between align-items-start flex-wrap mb-2" }
const _hoisted_15 = { class: "d-flex flex-column" }
const _hoisted_16 = { class: "flex-wrap d-flex align-items-center mb-2" }
const _hoisted_17 = {
  href: "#",
  class: "text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
}
const _hoisted_18 = {
  key: 0,
  class: "m-1 badge badge-info text-white"
}
const _hoisted_19 = {
  key: 1,
  class: "m-1 badge badge-warning text-white"
}
const _hoisted_20 = {
  key: 2,
  class: "m-1 badge badge-success"
}
const _hoisted_21 = {
  key: 3,
  class: "m-1 badge badge-dark"
}
const _hoisted_22 = { class: "d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2" }
const _hoisted_23 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_24 = { class: "d-flex align-items-center text-gray-400 text-hover-primary mb-2 me-1" }
const _hoisted_25 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_26 = ["href"]
const _hoisted_27 = {
  key: 0,
  class: "d-flex align-items-center text-gray-400 text-hover-primary mb-2 me-2"
}
const _hoisted_28 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_29 = ["href"]
const _hoisted_30 = {
  key: 1,
  class: "d-flex align-items-center text-gray-400 text-hover-primary mb-2"
}
const _hoisted_31 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_32 = ["href"]
const _hoisted_33 = { class: "d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2" }
const _hoisted_34 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_35 = { class: "d-flex align-items-center text-gray-400 text-hover-primary mb-2 me-1" }
const _hoisted_36 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_37 = ["href"]
const _hoisted_38 = {
  key: 0,
  class: "d-flex align-items-center text-gray-400 text-hover-primary mb-2 me-2"
}
const _hoisted_39 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_40 = ["href"]
const _hoisted_41 = {
  key: 1,
  class: "d-flex align-items-center text-gray-400 text-hover-primary mb-2"
}
const _hoisted_42 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_43 = ["href"]
const _hoisted_44 = {
  key: 1,
  class: "d-flex flex-wrap fw-bold fs-6 mb-4 pe-2"
}
const _hoisted_45 = {
  key: 2,
  class: "d-flex flex-wrap fw-bold fs-6 mb-4 pe-2"
}
const _hoisted_46 = { class: "d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2" }
const _hoisted_47 = { class: "ms-2" }
const _hoisted_48 = { key: 3 }
const _hoisted_49 = { class: "d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2" }
const _hoisted_50 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_51 = { class: "d-flex flex-wrap fw-bold fs-6 mb-4 pe-2" }
const _hoisted_52 = { key: 4 }
const _hoisted_53 = { class: "d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2" }
const _hoisted_54 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_55 = { class: "d-flex flex-wrap fw-bold fs-6 mb-4 pe-2" }
const _hoisted_56 = { class: "m-1 badge badge-light-primary" }
const _hoisted_57 = {
  key: 5,
  class: "d-flex flex-wrap fw-bold fs-6 mb-4 pe-2"
}
const _hoisted_58 = ["data-bs-toggle"]
const _hoisted_59 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_60 = { class: "ms-2" }
const _hoisted_61 = {
  key: 6,
  class: "d-flex flex-wrap fw-bold fs-6 mb-4 pe-2"
}
const _hoisted_62 = ["data-bs-toggle"]
const _hoisted_63 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_64 = { class: "ms-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "symbol-label",
          style: _normalizeStyle({ backgroundImage: `url(${_ctx.userAvatar})` })
        }, null, 4),
        (_ctx.user.changed)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4))
          : _createCommentVNode("", true)
      ]),
      (_ctx.currentUser.role_id > 3)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (
            _ctx.currentUser.role_id > 4 &&
            _ctx.user.filled_invoice_address &&
            !_ctx.non_interactive
          )
              ? (_openBlock(), _createBlock(_component_el_tooltip, {
                  key: 0,
                  effect: "dark",
                  content: _ctx.translate('users.toolTips.createInvoice'),
                  placement: "top-end"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: `/add-invoice-for-user/${_ctx.user.id}`,
                      class: "btn btn-link btn-active-color-primary btn-color-primary",
                      href: "#"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_6, [
                          _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen005.svg" })
                        ])
                      ]),
                      _: 1
                    }, 8, ["to"])
                  ]),
                  _: 1
                }, 8, ["content"]))
              : _createCommentVNode("", true),
            (_ctx.canLogAs(_ctx.user))
              ? (_openBlock(), _createBlock(_component_el_tooltip, {
                  key: 1,
                  effect: "dark",
                  content: 
            _ctx.translate('loginAs', 'users.toolTips', {
              user: `${_ctx.user.name} ${_ctx.user.surname}`,
            })
          ,
                  placement: "top-end"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("a", {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loginAsUser(_ctx.user))),
                      class: "btn btn-color-mutted btn-link btn-active-color-danger",
                      href: "#"
                    }, [
                      _createElementVNode("span", _hoisted_7, [
                        _createVNode(_component_inline_svg, { src: "/media/icons/duotune/abstract/abs037.svg" })
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["content"]))
              : _createCommentVNode("", true),
            (!_ctx.non_interactive)
              ? (_openBlock(), _createBlock(_component_el_tooltip, {
                  key: 2,
                  effect: "dark",
                  content: 
            _ctx.translate(
              !_ctx.user.invited ? 'invite' : 'inviteAgain',
              'users.toolTips',
              {
                user: `${_ctx.user.name} ${_ctx.user.surname}`,
              }
            )
          ,
                  placement: "top-end"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("a", {
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.inviteUser(_ctx.user))),
                      class: _normalizeClass(["btn btn-link btn-active-color-primary", _ctx.user.invited ? 'btn-color-success' : 'btn-color-muted']),
                      href: "#"
                    }, [
                      _createElementVNode("span", _hoisted_8, [
                        _createVNode(_component_inline_svg, { src: "/media/icons/duotune/communication/com002.svg" })
                      ])
                    ], 2)
                  ]),
                  _: 1
                }, 8, ["content"]))
              : _createCommentVNode("", true),
            _createVNode(_component_el_tooltip, {
              effect: "dark",
              content: _ctx.translate('users.toolTips.loggedThisWeek'),
              placement: "top-end"
            }, {
              default: _withCtx(() => [
                (_ctx.currentUser.role_id > 2)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: _normalizeClass(["me-2 bi bi-circle-fill", _ctx.user.logged_this_week ? 'text-success' : 'text-danger'])
                    }, null, 2))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["content"]),
            _createVNode(_component_el_tooltip, {
              effect: "dark",
              content: _ctx.translate('users.toolTips.programsStatus'),
              placement: "top-end"
            }, {
              default: _withCtx(() => [
                (_ctx.currentUser.role_id > 2)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: _normalizeClass(["me-2 bi bi-square-fill", 
              _ctx.user.programs_status ? 'text-' + _ctx.user.programs_status : 'd-none'
            ])
                    }, null, 2))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["content"]),
            _createVNode(_component_el_tooltip, {
              effect: "dark",
              content: _ctx.translate('users.toolTips.trainingsStatus'),
              placement: "top-end"
            }, {
              default: _withCtx(() => [
                (_ctx.currentUser.role_id > 2)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: _normalizeClass(["me-2 bi bi-triangle-fill", 
              _ctx.user.trainings_status ? 'text-' + _ctx.user.trainings_status : 'd-none'
            ])
                    }, null, 2))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["content"]),
            _createVNode(_component_el_tooltip, {
              effect: "dark",
              content: _ctx.translate('users.toolTips.programPaidStatus'),
              placement: "top-end"
            }, {
              default: _withCtx(() => [
                (_ctx.currentUser.role_id > 2)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: _normalizeClass(["me-2 bi bi-star-fill", 
              _ctx.user.program_paid_status
                ? 'text-' + _ctx.user.program_paid_status
                : 'd-none'
            ])
                    }, null, 2))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["content"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.currentUser.role_id > 3)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("span", _hoisted_10, [
              _createElementVNode("span", {
                class: _normalizeClass(["svg-icon svg-icon-4 me-1", _ctx.user.privacy.gdpr ? 'svg-icon-success' : 'svg-icon-warning'])
              }, [
                _createVNode(_component_inline_svg, {
                  src: 
                '/media/icons/duotune/general/gen0' +
                (_ctx.user.privacy.gdpr ? '43' : '40') +
                '.svg'
              
                }, null, 8, ["src"])
              ], 2),
              _cache[3] || (_cache[3] = _createTextVNode(" Gdpr"))
            ]),
            _createElementVNode("span", _hoisted_11, [
              _createElementVNode("span", {
                class: _normalizeClass(["svg-icon svg-icon-4 me-1", 
              _ctx.user.privacy.media ? 'svg-icon-success' : 'svg-icon-warning'
            ])
              }, [
                _createVNode(_component_inline_svg, {
                  src: 
                '/media/icons/duotune/general/gen0' +
                (_ctx.user.privacy.media ? '43' : '40') +
                '.svg'
              
                }, null, 8, ["src"])
              ], 2),
              _cache[4] || (_cache[4] = _createTextVNode(" Media"))
            ]),
            _createElementVNode("span", _hoisted_12, [
              _createElementVNode("span", {
                class: _normalizeClass(["svg-icon svg-icon-4 me-1", 
              _ctx.user.privacy.research ? 'svg-icon-success' : 'svg-icon-warning'
            ])
              }, [
                _createVNode(_component_inline_svg, {
                  src: 
                '/media/icons/duotune/general/gen0' +
                (_ctx.user.privacy.research ? '43' : '40') +
                '.svg'
              
                }, null, 8, ["src"])
              ], 2),
              _createTextVNode(" " + _toDisplayString(_ctx.translate("research_badge_label", "privacy")), 1)
            ]),
            (!_ctx.non_interactive)
              ? (_openBlock(), _createBlock(_component_el_tooltip, {
                  key: 0,
                  effect: "dark",
                  content: _ctx.translate('privacyChanged', 'users.toolTips'),
                  placement: "top-end"
                }, {
                  default: _withCtx(() => [
                    (_ctx.user.privacy_change?.changed)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          style: {"bottom":"2px"},
                          class: "svg-icon svg-icon-1 me-1 cursor-pointer svg-icon-info position-relative",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.checkPrivacyChange(_ctx.user.privacy_change.record)))
                        }, [
                          _createVNode(_component_inline_svg, { src: '/media/icons/duotune/general/gen044.svg' })
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["content"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("a", _hoisted_17, _toDisplayString(_ctx.user.name) + " " + _toDisplayString(_ctx.user.surname), 1),
            (_ctx.user.employment)
              ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.user.employment), 1))
              : _createCommentVNode("", true),
            (_ctx.user.developer)
              ? (_openBlock(), _createElementBlock("span", _hoisted_19, "Developer"))
              : _createCommentVNode("", true),
            (_ctx.user.therapy)
              ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_ctx.translate("users.account.badges.training")), 1))
              : _createCommentVNode("", true),
            (_ctx.user.role_id === 2 && _ctx.user.user_group)
              ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(_ctx.user.user_group.name), 1))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.user.sports, (sport) => {
              return (_openBlock(), _createElementBlock("span", {
                key: `sport-id-${sport.id}`,
                class: "m-1 badge badge-secondary"
              }, _toDisplayString(sport.name), 1))
            }), 128))
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["d-flex flex-wrap fw-bold fs-6 pe-2", _ctx.user.specializations?.length ? 'mb-0' : 'mb-4'])
          }, [
            _createElementVNode("span", _hoisted_22, [
              _createElementVNode("span", _hoisted_23, [
                _createVNode(_component_inline_svg, { src: "/media/icons/duotune/communication/com006.svg" })
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.translate(`users.roles.${_ctx.user.role.name}.title`)), 1)
            ]),
            _createElementVNode("span", _hoisted_24, [
              _createElementVNode("span", _hoisted_25, [
                _createVNode(_component_inline_svg, { src: "/media/icons/duotune/communication/com011.svg" })
              ]),
              _createElementVNode("a", {
                href: `mailto:${_ctx.user.email}`
              }, _toDisplayString(_ctx.user.email), 9, _hoisted_26)
            ]),
            (_ctx.user.phone)
              ? (_openBlock(), _createElementBlock("span", _hoisted_27, [
                  _createElementVNode("span", _hoisted_28, [
                    _createVNode(_component_inline_svg, { src: "/media/icons/duotune/electronics/elc002.svg" })
                  ]),
                  _createElementVNode("a", {
                    href: `tel:${_ctx.user.phone}`
                  }, _toDisplayString(_ctx.user.phone), 9, _hoisted_29)
                ]))
              : _createCommentVNode("", true),
            (_ctx.user.phone)
              ? (_openBlock(), _createElementBlock("span", _hoisted_30, [
                  _createElementVNode("span", _hoisted_31, [
                    _createVNode(_component_inline_svg, { src: "/media/icons/duotune/communication/com003.svg" })
                  ]),
                  _createElementVNode("a", {
                    href: `sms:${_ctx.user.phone}`
                  }, "SMS", 8, _hoisted_32)
                ]))
              : _createCommentVNode("", true)
          ], 2),
          (_ctx.userLegalRepresentative)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["d-flex flex-wrap fw-bold fs-6 pe-2", _ctx.user.specializations?.length ? 'mb-0' : 'mb-4'])
              }, [
                _createElementVNode("span", _hoisted_33, [
                  _createElementVNode("span", _hoisted_34, [
                    _createVNode(_component_inline_svg, { src: "/media/icons/duotune/communication/com006.svg" })
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.translate(`users.roles.legalRepresentative.title`)), 1)
                ]),
                _createElementVNode("span", _hoisted_35, [
                  _createElementVNode("span", _hoisted_36, [
                    _createVNode(_component_inline_svg, { src: "/media/icons/duotune/communication/com011.svg" })
                  ]),
                  _createElementVNode("a", {
                    href: `mailto:${_ctx.userLegalRepresentative.email}`
                  }, _toDisplayString(_ctx.userLegalRepresentative.email), 9, _hoisted_37)
                ]),
                (_ctx.userLegalRepresentative.phone)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_38, [
                      _createElementVNode("span", _hoisted_39, [
                        _createVNode(_component_inline_svg, { src: "/media/icons/duotune/electronics/elc002.svg" })
                      ]),
                      _createElementVNode("a", {
                        href: `tel:${_ctx.userLegalRepresentative.phone}`
                      }, _toDisplayString(_ctx.userLegalRepresentative.phone), 9, _hoisted_40)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.user.phone)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_41, [
                      _createElementVNode("span", _hoisted_42, [
                        _createVNode(_component_inline_svg, { src: "/media/icons/duotune/communication/com003.svg" })
                      ]),
                      _createElementVNode("a", {
                        href: `sms:${_ctx.userLegalRepresentative.phone}`
                      }, "SMS", 8, _hoisted_43)
                    ]))
                  : _createCommentVNode("", true)
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.user.specializations?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.user.specializations, (specialization) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: specialization.id,
                    class: "m-1 badge badge-light-primary"
                  }, _toDisplayString(specialization.name), 1))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.age)
            ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
                _createElementVNode("span", _hoisted_46, [
                  _createTextVNode(_toDisplayString(_ctx.translate("users.account.overview.form.age")) + " ", 1),
                  _createElementVNode("span", _hoisted_47, _toDisplayString(_ctx.age), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          (
              false && _ctx.user.role_id === 2 && _ctx.user.assigned_specialists?.length
            )
            ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
                _createElementVNode("span", _hoisted_49, [
                  _createElementVNode("span", _hoisted_50, [
                    _createVNode(_component_inline_svg, { src: "/media/icons/duotune/coding/cod002.svg" })
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.translate(`users.account.settings.form.specialists`)), 1)
                ]),
                _createElementVNode("div", _hoisted_51, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.user.assigned_specialists, (specialist) => {
                    return (_openBlock(), _createElementBlock("span", {
                      key: specialist.id,
                      class: "m-1 badge badge-light-primary"
                    }, _toDisplayString(specialist.name) + " " + _toDisplayString(specialist.surname) + " ( " + _toDisplayString(specialist.phone) + ")", 1))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true),
          (
              _ctx.user.role_id === 2 && _ctx.user.overseers?.length && _ctx.userCoordinator
            )
            ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                _createElementVNode("span", _hoisted_53, [
                  _createElementVNode("span", _hoisted_54, [
                    _createVNode(_component_inline_svg, { src: "/media/icons/duotune/coding/cod002.svg" })
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.translate(`users.overseers.roles.coordinator.title`)), 1)
                ]),
                _createElementVNode("div", _hoisted_55, [
                  _createElementVNode("span", _hoisted_56, _toDisplayString(_ctx.userCoordinator.name) + " " + _toDisplayString(_ctx.userCoordinator.surname) + " ( " + _toDisplayString(_ctx.userCoordinator.phone) + ")", 1)
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.currentUser.role_id > 2 && _ctx.user.logins?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_57, [
                _createElementVNode("span", {
                  class: _normalizeClass(["c d-flex align-items-center text-gray-400 me-5 mb-2", 
                !_ctx.non_interactive ? 'cursor-pointer text-hover-primary' : ''
              ]),
                  "data-bs-toggle": !_ctx.non_interactive ? 'modal' : '',
                  "data-bs-target": '#login-history_modal'
                }, [
                  _createElementVNode("span", _hoisted_59, [
                    _createVNode(_component_inline_svg, { src: "/media/icons/duotune/arrows/arr042.svg" })
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.translate("users.account.lastLogin")) + " ", 1),
                  _createElementVNode("span", _hoisted_60, _toDisplayString(_ctx.formatLoginDateTime(_ctx.user.logins[0].created_at)), 1)
                ], 10, _hoisted_58)
              ]))
            : _createCommentVNode("", true),
          (_ctx.currentUser.role_id > 2 && _ctx.user.last_contact_event)
            ? (_openBlock(), _createElementBlock("div", _hoisted_61, [
                _createElementVNode("span", {
                  class: _normalizeClass(["d-flex align-items-center text-gray-400 me-5 mb-2", 
                !_ctx.non_interactive ? 'cursor-pointer text-hover-primary' : ''
              ]),
                  "data-bs-toggle": !_ctx.non_interactive ? 'modal' : '',
                  "data-bs-target": '#user-contact-events'
                }, [
                  _createElementVNode("span", _hoisted_63, [
                    _createVNode(_component_inline_svg, { src: "/media/icons/duotune/communication/com003.svg" })
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.translate("users.account.lastContacted")) + " ", 1),
                  _createElementVNode("span", _hoisted_64, [
                    _createTextVNode(_toDisplayString(_ctx.formatLoginDateTime(_ctx.user.last_contact_event.created_at)) + " ", 1),
                    _createElementVNode("small", null, "(" + _toDisplayString(_ctx.translate(
                      "reasons." + _ctx.user.last_contact_event.reason,
                      "userContactEvents"
                    )) + ")", 1)
                  ])
                ], 10, _hoisted_62)
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}