import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_clients_after_diagnostic = _resolveComponent("clients-after-diagnostic")!
  const _component_clients_training_renewal = _resolveComponent("clients-training-renewal")!
  const _component_ClientsEndingProgram = _resolveComponent("ClientsEndingProgram")!
  const _component_ClientsOrderDiagnostic = _resolveComponent("ClientsOrderDiagnostic")!
  const _component_ClientsFirstWeekOfTraining = _resolveComponent("ClientsFirstWeekOfTraining")!
  const _component_ClientsLeads = _resolveComponent("ClientsLeads")!
  const _component_ClientsScheduledToContact = _resolveComponent("ClientsScheduledToContact")!

  return (_ctx.currentUser.role_id > 3)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_clients_after_diagnostic, {
          onSave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleReportSave($event))),
          loading: _ctx.loading
        }, null, 8, ["loading"]),
        _createVNode(_component_clients_training_renewal, {
          onSave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleReportSave($event))),
          loading: _ctx.loading
        }, null, 8, ["loading"]),
        _createVNode(_component_ClientsEndingProgram, {
          onSave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleReportSave($event))),
          loading: _ctx.loading
        }, null, 8, ["loading"]),
        _createVNode(_component_ClientsOrderDiagnostic, {
          onSave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleReportSave($event))),
          loading: _ctx.loading
        }, null, 8, ["loading"]),
        _createVNode(_component_ClientsFirstWeekOfTraining, {
          onSave: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleReportSave($event))),
          loading: _ctx.loading
        }, null, 8, ["loading"]),
        _createVNode(_component_ClientsLeads, {
          onSave: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleReportSave($event))),
          loading: _ctx.loading
        }, null, 8, ["loading"]),
        _createVNode(_component_ClientsScheduledToContact, {
          onSave: _cache[6] || (_cache[6] = ($event: any) => (_ctx.handleReportSave($event))),
          loading: _ctx.loading
        }, null, 8, ["loading"])
      ]))
    : _createCommentVNode("", true)
}