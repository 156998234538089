<template>
  <!--begin::Modal - New Address-->
  <div
    class="modal fade"
    ref="userContactEventModalRef"
    :id="'user_contact_event_modal_' + reason"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-1000px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Form-->
        <div
          class="modal-header py-10 px-lg-17"
          id="kt_modal_user_contact_event_header"
        >
          <!--begin::Modal title-->
          <div v-if="display_account_header" class="d-flex align-items-center">
            <account-details
              v-loading="loadingUser"
              :non_interactive="true"
            ></account-details>
          </div>
          <h2 v-else>
            {{
              translate("title", undefined, {
                name: `${user.name} ${user.surname}`,
              })
            }}
          </h2>
          <!--end::Modal title-->

          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <div class="modal-body py-10 px-lg-17">
          <!--begin::Scroll-->
          <div
            class="scroll-y me-n7 pe-7"
            id="kt_modal_user_contact_event_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_user_contact_event_header"
            data-kt-scroll-wrappers="#kt_modal_user_contact_event_scroll"
            data-kt-scroll-offset="300px"
          >
            <user-contact-event-form
              :reason="reason"
              :user="user"
              @save="handleFormSubmit($event)"
            ></user-contact-event-form>
            <h4 class="mt-10">
              {{ translate("table.history", "notifications") }}
            </h4>
            <crm-user-notifications :user="user"></crm-user-notifications>
            <h4 class="mt-10">{{ translate("history.title", "crm") }}</h4>
            <crm-user-history :user="user"></crm-user-history>
            <!--end::Form-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { User } from "@/store/interfaces/User";
import UserContactEventForm from "@/components/forms/user/UserContactEventForm.vue";
import { useTranslate } from "@/composables/translate";
import { hideModal } from "@/core/helpers/dom";
import AccountDetails from "@/views/crafted/account/parts/AccountDetails.vue";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import CrmUserHistory from "@/views/crm/components/CrmUserHistory.vue";
import CrmUserNotifications from "@/views/crm/components/CrmUserNotifications.vue";

export default defineComponent({
  emits: ["save"],
  props: {
    user: {
      type: Object as () => User,
      required: true,
    },
    reason: {
      type: String,
      required: true,
    },
    display_account_header: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  name: "user-contact-event-modal",
  components: {
    CrmUserHistory,
    CrmUserNotifications,
    AccountDetails,
    UserContactEventForm,
  },
  setup(props, { emit }) {
    const loadingUser = ref<boolean>(false);
    watch(
      () => props.user.id,
      () => {
        loadUser();
      }
    );

    onMounted(() => {
      loadUser();
    });

    const loadUser = () => {
      if (props.user && props.user.id) {
        loadingUser.value = true;
        store.dispatch(Actions.GET_USER_BY_ID, props.user.id).finally(() => {
          loadingUser.value = false;
        });
      }
    };

    const { translate } = useTranslate("userContactEvents");
    const userContactEventModalRef = ref<null | HTMLElement>(null);
    const handleFormSubmit = (modalData) => {
      emit("save", { ...modalData, ...{} });
      hideModal(userContactEventModalRef.value);
    };
    return {
      loadingUser,
      userContactEventModalRef,
      translate,
      handleFormSubmit,
    };
  },
});
</script>
