import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { PaginationResponse } from "@/store/interfaces/Datatables";
import { SortingPair } from "@/store/interfaces/Datatables";
import type {
  PriceCalculatorResult,
  Program,
} from "@/store/interfaces/Program";

@Module
export default class ProgramModule extends VuexModule {
  redirectTo = "";
  loadedProgram: Program = {} as Program;
  Programs: Program[] = [];
  pagination: PaginationResponse = {} as PaginationResponse;
  sorting: SortingPair = {} as SortingPair;

  get getLoadedProgram(): Program {
    return {
      ...this.loadedProgram,
      ...{ specializations: this.loadedProgram.specializations ?? [] },
      ...{ unit_prices: this.loadedProgram.unit_prices ?? [] },
      ...{ trainings: this.loadedProgram.trainings ?? [] },
      ...{ training_templates: this.loadedProgram.training_templates ?? [] },
      ...{ accessories: this.loadedProgram.accessories ?? [] },
      ...{
        additional_data: Object.assign(
          {
            diagnostics_alert: {
              period: null,
              quantity: null,
            },
            program_beginning: {
              period: null,
              quantity: null,
            },
          },
          this.loadedProgram.additional_data
        ),
      },
    } as Program;
  }

  /**
   * Retreive current loaded programs
   */
  get getPrograms(): Program[] {
    return this.Programs;
  }

  get getProgramTypes(): Array<string> {
    return ["basic", "grouped"];
  }

  /**
   * Get last pagination setup
   */
  get getProgramsPagination(): PaginationResponse {
    return this.pagination;
  }

  get getProgramsSorting() {
    return this.sorting;
  }

  @Mutation
  [Mutations.SET_LOADED_PROGRAM](Program: Program) {
    this.loadedProgram = Program;
  }

  @Mutation
  [Mutations.SET_PROGRAMS](data) {
    this.Programs = data.data;
  }

  @Mutation
  [Mutations.SET_PROGRAMS_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_PROGRAMS_SORTING](data) {
    this.sorting = data;
  }

  @Action
  [Actions.LOAD_PROGRAMS](payload) {
    ApiService.setHeader();
    return new Promise<Program[]>((resolve, reject) => {
      ApiService.post("programs", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_PROGRAMS, data["programs"]);
            this.context.commit(
              Mutations.SET_PROGRAMS_PAGINATION,
              data["programs"].pagination
            );
            resolve(data["programs"].data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "programs.errors.cantGetPrograms"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.GET_PROGRAM_BY_ID](id: number) {
    ApiService.setHeader();
    if (id == undefined) {
      return new Promise<Program>((resolve) => {
        const Program = {} as Program;
        this.context.commit(Mutations.SET_LOADED_PROGRAM, Program);
        resolve(Program);
      });
    }
    return new Promise<Program>((resolve, reject) => {
      ApiService.get(`program/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_PROGRAM, data["program"]);
            resolve(data["program"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "programs.errors.commonGET_PROGRAM_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_PROGRAM_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<Program>((resolve, reject) => {
      ApiService.delete(`program/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_PROGRAM, data["program"]);
            resolve(data["program"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "programs.errors.commonDELETE_PROGRAM_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_PROGRAM](Program: Program) {
    const payload = JSON.parse(JSON.stringify(Program));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`program/${Program.id}`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_PROGRAM, data["program"]);
            resolve();
          } else {
            const i18string = data.i18;
            const i18params = {
              id: Program.id,
              name: Program.name,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "programs.form.programChangedFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_PROGRAM](Program: Program) {
    const payload = JSON.parse(JSON.stringify(Program));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`program`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_PROGRAM, data["program"]);
            resolve(data["program"]);
          } else {
            const i18string = data.i18;
            const i18params = {
              id: Program.id,
              name: Program.name,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "programs.form.programAddFail"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.CALCULATE_PROGRAM_PRICE](payload: {
    program_id: number;
    period: string;
    currency: string;
    quantity: number;
  }) {
    ApiService.setHeader();
    return new Promise<PriceCalculatorResult>((resolve, reject) => {
      ApiService.post(`program-price-calculation`, payload)
        .then(({ data }) => {
          if (data.success) {
            resolve(data["calculation"]);
          } else {
            const i18string = data.i18;
            this.context.commit(Mutations.SET_ERROR, { i18string });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "programs.form.programPriceCalculationFail"
          );
          reject();
        });
    });
  }
}
