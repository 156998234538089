export const invoiceSk = {
  invoices: {
    crm: {
      manualPairing: "Spárováno ručně",
      autoPairing: "Spárováno automatem",
    },
    linkedPaymentsModal: {
      title: "Zpárované platby pro fakturu {name}",
      noRecords: "K této faktuře nebyly nalezený žádné Zpárované platby",
      linkPayment: "Zpárovat platbu",
      deletePrompt:
        "Opravdu chcete zrušit vazbu na platbu: <strong>{payment}</strong>?",
      linkPrompt:
        "Opravdu chcete vytvořit vazbu na platbu: <strong>{payment}</strong>?",
    },
    paymentSelectorModal: {
      title: "Vyberte platbu pro zpárování s fakturou {name}",
      selectPayment: "Potvrdit",
    },
    paymentStates: {
      paid: "plně uhrazena",
      "not-paid": "neuhrazena",
      overpaid: "uhrazená částka je vyšší než hodnota faktury",
      underpaid: "uhrazená částka je nižší než hodnota faktury",
    },
    toolTips: {
      edit: "Upraviť faktúru {invoice}",
      delete: "Odstrániť faktúru {invoice}",
      cancel: "Stornovat fakturu {invoice}",
      restore: "Obnovit fakturu {invoice}",
      clone: "Duplikovať faktúru {invoice}",
      client: "Zobrazit klienta pro fakturu {invoice}",
      download: "Stiahnuť PDF k faktúre {invoice}",
      paymentInstructions: "Stiahnuť informace k platbě pro {invoice}",
      sendEmail: "Odoslať email s faktúrou {invoice} klientovi",
      setPaid: "Označiť faktúru {invoice} ako `zaplatená`",
      setUnPaid: "Označiť faktúru {invoice} ako `nezaplatená`",
      linkedPayments:
        "Zpárované platby pro fakturu {invoice}  <br /> Stav platby: <strong>{payment_state}</strong>",
    },
    editTitle: "Upraviť faktúru",
    newTitle: "Nová faktúra",
    addInvoice: "Pridať faktúru",
    accountantExport: "Účtovný export",
    errors: {
      notFound: "Faktúra s ID {id} nebola nájdená",
      notYourRole: "Nemáte právo zobraziť faktúru s ID {id}",
      invoiceNumberExists: "Faktúra s číslom `{name}` už existuje",
      commonGET_INVOICE_BY_ID: "Chyba pri získavaní údajov o faktúre",
      commonGET_INVOICE_PDF_BY_ID: "Chyba pri získavaní PDF faktúry",
      commonDELETE_INVOICE_BY_ID: "Chyba pri odstraňovaní faktúry",
      commonCANCEL_INVOICE_BY_ID: "Chyba při stornování faktury",
      commonDELETE_INVOICES: "Nepodarilo sa odstrániť faktúru",
      SEND_INVOICE_PDF_BY_ID: "Nepodarilo sa odoslať faktúru",
      GET_INVOICE_PDF_BY_ID: "Nepodarilo sa vygenerovať PDF faktúry",
      GET_INVOICE_PAYMENT_INSTRUCTIONS_BY_ID:
        "Nepodarilo se vygenerovat intrukce k platbě",
      GHANGE_INVOICE_PAID_STATUS_BY_ID:
        "Nepodarilo sa upraviť stav platby u faktúry",
      cantGetInvoices: "Chyba pri získavaní údajov o faktúrach",
      cantGetNextInvoiceNumber: "Chyba pri generovaní nového čísla faktúry",
      cantGetOrganizationsByUserGroupId:
        "Chyba pri získavaní organizácií klienta",
      cantGetAccountantExport: "Nepodarilo sa vygenerovať export pre účtovníka",
      cantGetExport: "Nepodarilo sa vygenerovať export",
    },
    form: {
      number: "Číslo",
      company: "Firma",
      organization: "Organizácia",
      userProgram: "Program",
      name: "Názov",
      currency: "Mena",
      payment: "Platba",
      issueDate: "Dátum vystavenia",
      paymentDate: "Dátum splatnosti",
      taxDate: "Dátum zdaniteľného plnenia",
      logo: "Logo",
      email: "Email",
      country: "Krajina",
      client: "Klient",
      street: "Ulica",
      city: "Obec",
      zip: "PSČ",
      in: "IČ",
      tin: "DIČ",
      invoiceChanged: "Faktúra bola upravená",
      invoiceChangedFail: "Nepodarilo sa upraviť faktúru",
      invoicePaymentsChangedFail: "Nepodařilo se upravit platby pro fakturu",
      invoicePaymentUnlinked: "Vazba na platbu byla zrušena",
      invoicePaymentLinked: "Platba byla zpárována s fakturou",
      invoiceAdded: "Nová faktúra bola vytvorená",
      invoiceSent: "Faktúra bola odoslaná na `{email}`",
      invoicePaid: "Faktúra bola označená ako zaplatená",
      invoiceUnPaid: "Faktúra bola označená ako nezaplatená",
      invoiceAddFail: "Nepodarilo sa vytvoriť novú faktúru",
      addItem: "Pridať položku",
      supplier: "Dodávateľ",
      customer: "Odberateľ",
      items: "Položky",
      notes: "Poznámky",
      note_before: "Pred položkami",
      note_after: "Za položkami",
      bankAccount: "Bankový účet",
      company_bank_name: "Názov banky",
      company_bank_account: "Č. účtu",
      company_bank_iban: "IBAN",
      company_bank_swift_bic: "SWIFT/BIC",
      productName: "Názov",
      quantity: "Množstvo",
      vat: "DPH",
      vat_rate: "Sazba DPH",
      price: "Cena s DPH",
      noItems: "Musíte zadať aspoň jednu položku faktúry",
      findClient: "Hľadať (meno / priezvisko)",
    },
    table: {
      header: {
        number: "Číslo",
        issueDate: "Dátum vystavenia",
        paymentDate: "Dátum splatnosti",
        company: "Firma",
        client: "Klient",
        organization: "Organizácia",
        issuer: "Vystavil",
        supplier: "Dodávateľ",
        totalPrice: "Cena celkom s DPH",
        customer: "Odberateľ",
        actions: "Akcie",
      },
      showAll: "Všechny faktury",
      showUnpaid: "Pouze nezaplacené",
      hideCanceled: "Skrýt stornované",
      showCanceled: "Zobrazit stornované",
      cancelInvoiceQuestion:
        "Opravdu chcete stornovat fakturu <strong>{invoice}</strong>?",
      restoreInvoiceQuestion:
        "Opravdu chcete obnovit fakturu <strong>{invoice}</strong>?",
      deleteInvoiceQuestion:
        "Naozaj chcete odstrániť faktúru <strong>{invoice}</strong>?",
      sendInvoiceQuestion:
        "Naozaj chcete odoslať faktúru <strong>{invoice}</strong> na <strong>{email}</strong>?",
      searchInvoices: "Hľadať faktúru",
      invoiceDeleted: "Faktúra {invoice} bola odstránená",
      invoiceCanceled: "Faktura {invoice} byla stornována",
      invoiceRestored: "Faktura {invoice} byla obnovena",
      invoicesDeleted: "Faktúry boli odstránené",
      noRecords: "Neboli nájdené žiadne faktúry",
    },
  },
};
