export const widgetCs = {
  widgets: {
    total: "Celkem",
    ClientsWithoutTraining: {
      title: "Klienti kteří nejsou v tréninku",
    },
    ClientsAfterTraining: {
      title: "Klienti po tréninku",
    },
    ClientsJustFinishedTraining: {
      title: "Klienti kteří právě ukončili trénink",
    },
    ClientsAfterDiagnostic: {
      title: "Klienti po diagnostice",
    },
    ClientsEndingProgram: {
      title: "Klienti s končícím tréninkem",
    },
    ClientsOrderDiagnostic: {
      title: "Klienti k objednání na diagnostiku",
    },
    ClientsLeads: {
      title: "Potenciální klienti",
    },
    ClientsFirstWeekOfTraining: {
      title: "Klienti v prvním týdnu tréninku",
    },
    ClientsTrainingRenewal: {
      title: "Obnovení tréninku",
    },
    ChatMessagesHistory: {
      title: "Historie chatu",
      searchMessages: "Hledat ve zprávách",
      tooltips: {
        openChat: "Otevřít chat",
        markAsUnread: "Označit jako nepřečtené",
      },
      table: {
        read: "Přečteno",
        created_at: "Datum",
      },
    },
    ClientsScheduledToContact: {
      title: "Klienti s plánovaným datem kontaktování",
      scheduledOn: "Plánováno na",
    },
    players: {
      title: "Seznam hráčů",
    },

    inOfficeStats: {
      categories: {
        eye_muscles: "Okohybné svaly",
        flexibility: "Flexibilita",
        flipper_right: "Pravé oko",
        flipper_left: "Levé oko",
        total: "Celkem",
      },
    },
    eyeFlexibilityStats: {
      categories: {
        eye_muscles: "Okohybné svaly",
        flexibility: "Flexibilita",
        flipper_right: "Pravé oko",
        flipper_left: "Levé oko",
        total: "Celkem",
      },
    },
    opticsStats: {
      categories: {
        refocus_both_eyes: "Rychlost zraku",
        eye_muscles: "Okohybné svaly",
        flexibility: "Flexibilita",
        flipper_right: "Pravé oko",
        flipper_left: "Levé oko",
        total: "Celkem",
      },
    },
    eyeStabilityStats: {
      categories: {
        eye_muscles: "Okohybné svaly",
        eye_muscles_far: "Svaly do dálky",
        eye_muscles_close: "Svaly na blízko",
        total: "Stabilita zraku",
      },
    },
    coach: {
      historyListTitle: "Měření",
      clientSettings: "Nastavení grafu",
      invalidDataMessage:
        "Vypadá to, že naměřená data silně vybočují z hodnot kterých je možné dosáhnout správným měřením.",
      clickForDetail: "Detail výsledku je k dipozici po rozkliknutí.",
      comparisionWithPreviousTranining: "porovnání s přechozím měřením",
      currentValue: "Aktuální měření",
      previousValue: "Předchozí měření",
      initialValue: "Vstupní měření",
      diagnosticTitles: {
        senses_board: "SensesBoard",
        in_office: "Okohybné svaly",
        eye_flexibility: "Flexibilita zraku",
        optics: "MySenses - měření",
        eye_stability: "Stabilita zraku",
        total: "Celkem",
      },
      results: "Výsledky",
      games: "Hry",
      radarTitle: "Porovnávací graf hráčů",
      radarComputedValues: {
        __average: "Průměrný graf týmu",
      },
      export: {
        title: "Report dat z isenses",
        button: "Vygenerovat report",
        pdfGenerated: "Report byl vygenerován",
        graphTitles: {
          total: "Porovnávací graf hráčů",
          average: "Průměrný graf týmu",
        },
      },
      gamesForClient: "Hry pro klienta",
      backToListOfClients: "Zpět na výběr klientů",
      group: "Skupina",
      filter: "Filtr",
      individuals: "Jednotlivci",
      noPlayers: "Nejsou k dispozici žádná data",
      noDiagnostics: "Nejsou k dispozici žádné výsledky diagnostiky",
      form: {
        userGroup: "Skupina",
        players: "Hráči",
      },
      errors: {
        cantGetRadarData: "Nepodařilo se stáhnout data pro radarový graf",
      },
    },
    sensesBoardStats: {
      title: "Výsledky SensesBoard",
      historyListTitle: "Měření",
      invalidDataMessage:
        "Vypadá to, že naměřená data silně vybočují z hodnot kterých je možné dosáhnout správným měřením.",
      SBPotential: {
        potential: "Potenciál",
        graph: {
          title: "Potenciál rozvoje",

          players: "Hráči",
          groups: "Skupiny",
          categories: {
            game_reading: "Pochopení situace",
            orientation_in_space: "Orientace v prostoru",
            refocus_both_eyes: "Rychlost zraku",
            coordination: "Přesnost zraku",
            understanding_situation: "Neurovision",
            total: "Celkem",
          },
        },
        modal: {
          title: "Potenciál jednotlivce",
          titleClient: "SensesBoard - Váš potenciál",
          performance: "Výkon",
          value: "Hodnota",
          recommendation: "Doporučení",
          potential: "Potenciál",
          graphs: {
            game_reading: {
              title: "Pochopení situace",
            },
            orientation_in_space: {
              title: "Orientace v prostoru",
            },
            refocus_both_eyes: {
              title: "Rychlost zraku",
            },
            coordination: {
              title: "Přesnost zraku",
            },
            understanding_situation: {
              title: "Neurovision",
            },
            total: {
              title: "Neurovision",
            },
          },
        },
      },
      SBNormativeIndex: {
        title: "Normativní index",
        total: "Celkový",
        rank: "Pořadí",
        name: "Jméno",
        index: "Index",
        group: "Skupina",
        legend: {
          aboveAverage: "Nadprůměr",
          average: "Průměr",
          bellowAverage: "Podprůměr",
        },
      },
      errors: {
        cantSensesBoardStats:
          "Nepodařilo se stáhnout statistická data SensesBoard",
        cantExportSensesBoardStats:
          "Nepodařilo se exportovat statistická data SensesBoard",
        cantLoadNiLegend: "Nepodařilo se stahnout pomocná data pro SensesBoard",
      },
    },
  },
};
