import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = ["data-bs-target", "aria-expanded", "aria-controls"]
const _hoisted_3 = { class: "card-title align-items-start flex-column" }
const _hoisted_4 = { class: "card-toolbar" }
const _hoisted_5 = { class: "svg-icon svg-icon-2hx svg-icon-info me-4" }
const _hoisted_6 = ["id"]
const _hoisted_7 = { class: "card-body py-3" }
const _hoisted_8 = {
  key: 0,
  class: "table-responsive"
}
const _hoisted_9 = { class: "symbol symbol-45px me-5" }
const _hoisted_10 = { class: "small" }
const _hoisted_11 = ["href"]
const _hoisted_12 = { class: "small" }
const _hoisted_13 = ["href"]
const _hoisted_14 = { class: "text-center" }
const _hoisted_15 = { class: "small" }
const _hoisted_16 = { class: "text-primary fw-bolder" }
const _hoisted_17 = { class: "btn-group-sm" }
const _hoisted_18 = { class: "svg-icon svg-icon-2x" }
const _hoisted_19 = ["data-bs-target", "onClick"]
const _hoisted_20 = { class: "svg-icon svg-icon-2x" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Datatable = _resolveComponent("Datatable")!
  const _component_user_contact_event_modal = _resolveComponent("user-contact-event-modal")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "card-header border-0 cursor-pointer",
        role: "button",
        "data-bs-toggle": "collapse",
        "data-bs-target": `#client-widget-${
        !_ctx.clients.length ? 'xx' : _ctx.contact_reason
      }`,
        "aria-expanded": !_ctx.clients.length ? false : _ctx.expanded,
        "aria-controls": `client-widget-${_ctx.contact_reason}`,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.expanded = !_ctx.clients.length ? false : !!!_ctx.expanded))
      }, [
        _createElementVNode("h3", _hoisted_3, [
          _createElementVNode("span", {
            class: _normalizeClass(["card-label fw-bolder fs-3 mb-1", !_ctx.clients.length ? 'text-black-50' : ''])
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(["svg-icon svg-icon-1", _ctx.clients.length ? 'svg-icon-primary' : 'svg-icon-secondary'])
            }, [
              _createVNode(_component_inline_svg, {
                src: '/media/icons/duotune/' + _ctx.icon
              }, null, 8, ["src"])
            ], 2),
            _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
          ], 2)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_inline_svg, {
              src: 
              _ctx.expanded
                ? '/media/icons/duotune/arrows/arr081.svg'
                : '/media/icons/duotune/arrows/arr082.svg'
            
            }, null, 8, ["src"])
          ])
        ])
      ], 8, _hoisted_2),
      _createElementVNode("div", {
        id: `client-widget-${_ctx.contact_reason}`,
        class: "collapse"
      }, [
        _createElementVNode("div", _hoisted_7, [
          (_ctx.clients.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_Datatable, {
                  "table-header": _ctx.tableHeader,
                  "table-data": _ctx.clients,
                  "enable-items-per-page-dropdown": false,
                  "rows-per-page": 10
                }, {
                  "cell-avatar": _withCtx(({ row: user }) => [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", {
                        class: "symbol-label",
                        style: _normalizeStyle({ backgroundImage: `url(${_ctx.userAvatar(user)})` })
                      }, null, 4)
                    ])
                  ]),
                  "cell-name": _withCtx(({ row: user }) => [
                    _createElementVNode("div", null, _toDisplayString(user.name) + " " + _toDisplayString(user.surname), 1),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("a", {
                        href: `mailto:${user.email}`
                      }, _toDisplayString(user.email), 9, _hoisted_11)
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("a", {
                        href: `tel:${user.phone}`
                      }, _toDisplayString(user.phone), 9, _hoisted_13)
                    ])
                  ]),
                  "cell-no-training-yet": _withCtx(({ row: user }) => [
                    _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.translate(user["no-training-yet"] ? "yes" : "no", "common")), 1)
                  ]),
                  "cell-notification_to_close": _withCtx(({ row: user }) => [
                    _createElementVNode("div", _hoisted_15, [
                      _createTextVNode(_toDisplayString(_ctx.translate("scheduledOn", "widgets.ClientsScheduledToContact")) + ": ", 1),
                      _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.dateFormat(user.notification_to_close.date_start)), 1),
                      _createVNode(_component_el_tooltip, {
                        content: 
                    user.notification_to_close.created_by_user?.name +
                    ' ' +
                    user.notification_to_close.created_by_user?.surname
                  
                      }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createElementVNode("i", { class: "ms-3 fa fa-user" }, null, -1)
                        ])),
                        _: 2
                      }, 1032, ["content"])
                    ]),
                    _createElementVNode("div", null, [
                      _createVNode(_component_el_tooltip, {
                        effect: "dark",
                        content: user.notification_to_close?.message,
                        placement: "top-end"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$filters.truncate(user.notification_to_close?.message, 15)), 1)
                        ]),
                        _: 2
                      }, 1032, ["content"])
                    ])
                  ]),
                  "cell-actions": _withCtx(({ row: user }) => [
                    _createElementVNode("div", _hoisted_17, [
                      _createVNode(_component_el_tooltip, {
                        effect: "dark",
                        content: 
                    _ctx.translate('edit', 'users.toolTips', {
                      user: `${user.name} ${user.surname}`,
                    })
                  ,
                        placement: "top-end"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_router_link, {
                            to: `/account/${user.id}/diagnostics`,
                            target: "_blank",
                            class: "btn btn-link btn-color-muted btn-active-color-primary"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_18, [
                                _createVNode(_component_inline_svg, { src: "/media/icons/duotune/communication/com006.svg" })
                              ])
                            ]),
                            _: 2
                          }, 1032, ["to"])
                        ]),
                        _: 2
                      }, 1032, ["content"]),
                      _createVNode(_component_el_tooltip, {
                        effect: "dark",
                        content: 
                    _ctx.translate('toolTips.contact', 'userContactEvents', {
                      name: `${user.name} ${user.surname}`,
                    })
                  ,
                        placement: "top-end"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("a", {
                            "data-bs-toggle": "modal",
                            "data-bs-target": 
                      '#user_contact_event_modal_' + _ctx.contact_reason
                    ,
                            class: "btn btn-link btn-color-muted btn-active-color-primary",
                            onClick: ($event: any) => (_ctx.selectUserToContact(user))
                          }, [
                            _createElementVNode("span", _hoisted_20, [
                              _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen055.svg" })
                            ])
                          ], 8, _hoisted_19)
                        ]),
                        _: 2
                      }, 1032, ["content"])
                    ])
                  ]),
                  _: 1
                }, 8, ["table-header", "table-data"])
              ]))
            : _createCommentVNode("", true)
        ])
      ], 8, _hoisted_6)
    ])), [
      [_directive_loading, _ctx.innerLoading]
    ]),
    (_openBlock(), _createBlock(_component_user_contact_event_modal, {
      key: _ctx.contact_reason,
      reason: _ctx.contact_reason,
      user: _ctx.selectedUser,
      onSave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleReportSave($event)))
    }, null, 8, ["reason", "user"]))
  ], 64))
}