import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row align-items-center align-middle" }
const _hoisted_2 = { class: "col-10" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  class: "text-end col-2"
}
const _hoisted_6 = {
  key: 0,
  class: "svg-icon svg-icon-2x"
}
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($props.record.data.description)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            innerHTML: $props.record.data.description
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
      ($props.record.data.description_i18)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            innerHTML: $setup.printTranslatedDescription($props.record)
          }, null, 8, _hoisted_4))
        : _createCommentVNode("", true)
    ]),
    ($props.record.data.link)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_router_link, {
            class: "btn btn-link btn-color-muted btn-active-color-primary",
            target: '_blank',
            to: $props.record.data.link
          }, {
            default: _withCtx(() => [
              ($props.record.data.icon)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                    _createVNode(_component_inline_svg, {
                      src: '/media/icons/duotune/' + $props.record.data.icon
                    }, null, 8, ["src"])
                  ]))
                : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString($props.record.data.link), 1))
            ]),
            _: 1
          }, 8, ["to"])
        ]))
      : _createCommentVNode("", true)
  ]))
}