export const accessorySk = {
  accessories: {
    title: "Príslušenstvo",
    toolTips: {
      edit: "Upraviť príslušenstvo {accessory}",
      delete: "Odstrániť príslušenstvo {accessory}",
    },
    editTitle: "Upraviť príslušenstvo",
    newTitle: "Nové príslušenstvo",
    addAccessory: "Pridať príslušenstvo",
    errors: {
      notFound: "Príslušenstvo s id {id} nebolo nájdené",
      notYourRole: "Nemáte práva zobraziť príslušenstvo s id {id}",
      nameExists: "Príslušenstvo s názvom `{name}` už existuje",
      codeExists: "Príslušenstvo s kódom `{code}` už existuje",
      commonGET_ACCESSORY_BY_ID: "Chyba pri získavaní údajov o príslušenstve",
      commonDELETE_ACCESSORY_BY_ID: "Chyba pri odstraňovaní príslušenstva",
      commonDELETE_ACCESSORIES: "Nepodarilo sa odstrániť príslušenstvo",
      cantGetAccessories: "Chyba pri získavaní údajov o príslušenstve",
    },
    form: {
      addAccesory: "Pridať príslušenstvo",
      name: "Názov",
      client_name: "Názov pre klienta",
      color: "Farba",
      code: "Kód",
      description: "Popis",
      image: "Obrázok",
      icon: "Ikona",
      company: "Firmy",
      programs: "Produkty",
      accessoryChanged: "Príslušenstvo bolo upravené",
      accessoryChangedFail: "Nepodarilo sa upraviť príslušenstvo",
      accessoryAdded: "Nové príslušenstvo bolo vytvorené",
      accessoryAddFail: "Nepodarilo sa vytvoriť nové príslušenstvo",
    },
    table: {
      header: {
        name: "Názov",
        icon: "Ikona",
        code: "Kód",
        programs: "Produkty",
        company: "Firmy",
        actions: "Akcie",
      },
      deleteAccessoryQuestion:
        "Naozaj chcete odstrániť príslušenstvo <strong>{accessory}</strong>?",
      deleteCheckInfo: "Pomůcka je použita v",
      deleteCheckTemplateCount: "šablonách cviků",
      deleteCheckActiveCount: "aktivních cvicích",
      searchAccessories: "Hľadať príslušenstvo",
      accessoryDeleted: "Príslušenstvo {accessory} bolo odstránené",
      accessoriesDeleted: "Príslušenstvá boli odstránené",
      noRecords: "Neboli nájdené žiadne príslušenstvá",
    },
  },
};
