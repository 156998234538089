<template>
  <!--begin::Tables Widget 1-->
  <div class="card" v-loading="innerLoading">
    <!--begin::Header-->
    <div
      class="card-header border-0 cursor-pointer"
      role="button"
      data-bs-toggle="collapse"
      :data-bs-target="`#client-widget-${
        !clients.length ? 'xx' : contact_reason
      }`"
      :aria-expanded="!clients.length ? false : expanded"
      :aria-controls="`client-widget-${contact_reason}`"
      @click="expanded = !clients.length ? false : !!!expanded"
    >
      <h3 class="card-title align-items-start flex-column">
        <span
          class="card-label fw-bolder fs-3 mb-1"
          :class="!clients.length ? 'text-black-50' : ''"
        >
          <span
            class="svg-icon svg-icon-1"
            :class="clients.length ? 'svg-icon-primary' : 'svg-icon-secondary'"
          >
            <inline-svg :src="'/media/icons/duotune/' + icon" />
          </span>
          {{ title }}</span
        >
      </h3>
      <div class="card-toolbar">
        <span class="svg-icon svg-icon-2hx svg-icon-info me-4">
          <inline-svg
            :src="
              expanded
                ? '/media/icons/duotune/arrows/arr081.svg'
                : '/media/icons/duotune/arrows/arr082.svg'
            "
          />
        </span>
      </div>
    </div>
    <!--end::Header-->

    <div :id="`client-widget-${contact_reason}`" class="collapse">
      <!--begin::Body-->
      <div class="card-body py-3">
        <!--begin::Table container-->
        <div class="table-responsive" v-if="clients.length">
          <Datatable
            :table-header="tableHeader"
            :table-data="clients"
            :enable-items-per-page-dropdown="false"
            :rows-per-page="10"
          >
            <template v-slot:cell-avatar="{ row: user }">
              <div class="symbol symbol-45px me-5">
                <div
                  class="symbol-label"
                  :style="{ backgroundImage: `url(${userAvatar(user)})` }"
                ></div>
              </div>
            </template>
            <template v-slot:cell-name="{ row: user }">
              <div>{{ user.name }} {{ user.surname }}</div>
              <div class="small">
                <a :href="`mailto:${user.email}`">{{ user.email }}</a>
              </div>
              <div class="small">
                <a :href="`tel:${user.phone}`">{{ user.phone }}</a>
              </div>
            </template>
            <template v-slot:cell-no-training-yet="{ row: user }">
              <div class="text-center">
                {{
                  translate(user["no-training-yet"] ? "yes" : "no", "common")
                }}
              </div>
            </template>
            <template v-slot:cell-notification_to_close="{ row: user }">
              <div class="small">
                {{
                  translate("scheduledOn", "widgets.ClientsScheduledToContact")
                }}:
                <span class="text-primary fw-bolder">{{
                  dateFormat(user.notification_to_close.date_start)
                }}</span>
                <el-tooltip
                  :content="
                    user.notification_to_close.created_by_user?.name +
                    ' ' +
                    user.notification_to_close.created_by_user?.surname
                  "
                >
                  <i class="ms-3 fa fa-user"></i>
                </el-tooltip>
              </div>
              <div>
                <el-tooltip
                  effect="dark"
                  v-bind:content="user.notification_to_close?.message"
                  placement="top-end"
                >
                  {{
                    $filters.truncate(user.notification_to_close?.message, 15)
                  }}
                </el-tooltip>
              </div>
            </template>

            <template v-slot:cell-actions="{ row: user }">
              <div class="btn-group-sm">
                <el-tooltip
                  effect="dark"
                  v-bind:content="
                    translate('edit', 'users.toolTips', {
                      user: `${user.name} ${user.surname}`,
                    })
                  "
                  placement="top-end"
                >
                  <router-link
                    :to="`/account/${user.id}/diagnostics`"
                    target="_blank"
                    class="btn btn-link btn-color-muted btn-active-color-primary"
                  >
                    <span class="svg-icon svg-icon-2x">
                      <inline-svg
                        src="/media/icons/duotune/communication/com006.svg"
                      /> </span
                  ></router-link>
                </el-tooltip>
                <el-tooltip
                  effect="dark"
                  v-bind:content="
                    translate('toolTips.contact', 'userContactEvents', {
                      name: `${user.name} ${user.surname}`,
                    })
                  "
                  placement="top-end"
                >
                  <a
                    data-bs-toggle="modal"
                    :data-bs-target="
                      '#user_contact_event_modal_' + contact_reason
                    "
                    class="btn btn-link btn-color-muted btn-active-color-primary"
                    @click="selectUserToContact(user)"
                  >
                    <span class="svg-icon svg-icon-2x">
                      <inline-svg
                        src="/media/icons/duotune/general/gen055.svg"
                      /> </span
                  ></a>
                </el-tooltip>
              </div>
            </template>
          </Datatable>
          <!--end::Table-->
        </div>
        <!--end::Table container-->
      </div>
    </div>
  </div>
  <!--endW::Tables Widget 1-->
  <user-contact-event-modal
    :key="contact_reason"
    :reason="contact_reason"
    :user="selectedUser"
    @save="handleReportSave($event)"
  ></user-contact-event-modal>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import Datatable, {
  IHeaderConfiguration,
} from "@/components/kt-datatable/KTDatatable.vue";
import {
  showUserAvatar,
  User,
  UserContactEvent,
} from "@/store/interfaces/User";
import UserContactEventModal from "@/components/modals/forms/UserContactEventModal.vue";
import { formatDate } from "@/core/helpers/date";

export default defineComponent({
  components: { UserContactEventModal, Datatable },
  name: "clients-widget-with-contact-report",
  emits: ["save"],
  props: {
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    contact_reason: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: "abstract/abs030.svg",
    },
    "table-header": {
      type: Array as () => Array<IHeaderConfiguration>,
      required: true,
    },
    clients: {
      type: Array as () => Array<User>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t, te } = useI18n();
    const innerLoading = ref(false);
    const translate = (text: string, root?: string, params = {}): string => {
      text = (root !== undefined ? root + "." : "widgets") + text;
      if (text) {
        if (te(text)) {
          return t(text, params);
        } else {
          return text;
        }
      } else {
        return "";
      }
    };

    const userAvatar = (user) => {
      return showUserAvatar(user);
    };

    const selectedUser = reactive<User>({} as User);

    const selectUserToContact = (user: User) => {
      Object.assign(selectedUser, user);
    };

    const handleReportSave = (event: UserContactEvent) => {
      Object.assign(selectedUser, {});
      innerLoading.value = true;
      setTimeout(() => {
        emit("save", event);
        innerLoading.value = false;
      }, 1000);
    };
    const expanded = ref(false);

    const dateFormat = (value) => formatDate(value);

    return {
      innerLoading,
      dateFormat,
      expanded,
      handleReportSave,
      translate,
      selectUserToContact,
      selectedUser,
      userAvatar,
    };
  },
});
</script>
