<template>
  <Form
    class="form"
    id="user_contact_event_form"
    @submit="submit"
    :validation-schema="validationSchema"
  >
    <h4>{{ translate("table.addRecord", "notifications") }}</h4>
    <!--begin::Input group-->
    <div class="row mb-6 d-none">
      <!--begin::Label-->
      <label class="col-lg-4 col-form-label fw-bold fs-6">
        <span>{{ translate("form.reason") }}</span>
      </label>
      <!--end::Label-->

      <!--begin::Col-->
      <div class="col-lg-8 position-relative fw-bold" style="top: 10px">
        {{ translate("reasons." + reason) }}
      </div>
    </div>

    <!--begin::Input group-->
    <div class="row mb-6">
      <!--begin::Label-->
      <label class="col-lg-4 col-form-label fw-bold fs-6">
        <span>{{ translate("form.contacted_via") }}</span>
      </label>
      <!--end::Label-->

      <!--begin::Col-->
      <div class="col-lg-12 d-md-flex d-block flex-wrap overflow-auto mh-150px">
        <div class="d-flex">
          <div class="btn-group">
            <button
              @click="setVia('phone')"
              type="button"
              class="btn"
              :class="
                eventData.contacted_via === 'phone'
                  ? 'btn-primary'
                  : 'btn-light-primary'
              "
            >
              <span class="svg-icon svg-icon-2"></span>
              {{ translate("via.phone") }}
            </button>
            <button
              @click="setVia('sms')"
              type="button"
              class="btn"
              :class="
                eventData.contacted_via === 'sms'
                  ? 'btn-primary'
                  : 'btn-light-primary'
              "
            >
              <span class="svg-icon svg-icon-2"></span>
              {{ translate("via.sms") }}
            </button>
            <button
              @click="setVia('email')"
              type="button"
              class="btn"
              :class="
                eventData.contacted_via === 'email'
                  ? 'btn-primary'
                  : 'btn-light-primary'
              "
            >
              <span class="svg-icon svg-icon-2"></span>
              {{ translate("via.email") }}
            </button>
            <button
              @click="setVia('in_person')"
              type="button"
              class="btn"
              :class="
                eventData.contacted_via === 'in_person'
                  ? 'btn-primary'
                  : 'btn-light-primary'
              "
            >
              <span class="svg-icon svg-icon-2"></span>
              {{ translate("via.in_person") }}
            </button>
            <button
              @click="setVia('not_contacted')"
              type="button"
              class="btn"
              :class="
                eventData.contacted_via === 'not_contacted'
                  ? 'btn-primary'
                  : 'btn-light-primary'
              "
            >
              <span class="svg-icon svg-icon-2"></span>
              {{ translate("via.not_contacted") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12 d-md-flex d-block flex-wrap overflow-auto mh-150px">
      <div class="d-flex">
        <div class="btn-group">
          <button
            @click="setVia('does_not_pick_up')"
            type="button"
            class="btn"
            :class="
              eventData.contacted_via === 'does_not_pick_up'
                ? 'btn-warning'
                : 'btn-light-warning'
            "
          >
            <span class="svg-icon svg-icon-2"></span>
            {{ translate("via.does_not_pick_up") }}
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <!--begin::Label-->
      <label class="col-lg-4 col-form-label fw-bold fs-6">
        <span>{{ translate("form.result") }}</span>
      </label>
      <!--end::Label-->

      <!--begin::Col-->
      <div class="col-lg-8 fv-row">
        <textarea
          name="otherPhones"
          class="form-control form-control-lg form-control-solid"
          v-model="eventData.result"
        />
      </div>
      <!--end::Col-->
    </div>

    <div class="row mt-10">
      <!--begin::Label-->
      <label class="col-lg-4 col-form-label fw-bold fs-6">{{
        translate("form.nextContactEvent")
      }}</label>
      <!--begin::Label-->

      <!--begin::Label-->
      <div class="col-lg-8 d-flex align-items-center">
        <div class="form-check form-check-solid form-switch fv-row">
          <input
            class="form-check-input w-45px h-30px"
            type="checkbox"
            id="nextContactEnabled"
            value="nextContactEnabled"
            v-model="nextContactEnabled"
          />
          <label class="form-check-label" for="nextContactEnabled"></label>
        </div>
      </div>
      <!--begin::Label-->
    </div>
    <!--end::Input group-->

    <!--begin::Input group-->
    <div class="row mt-5" v-if="nextContactEnabled">
      <!--begin::Label-->
      <div class="col">
        <el-date-picker
          v-model="eventData.next_contact_date"
          size="large"
          format="DD.MM.YYYY"
          :teleported="false"
          value-format="YYYY-MM-DD HH:mm:ss"
          type="date"
          :editable="false"
          :disabled-date="disabledDate"
          v-bind:placeholder="translate('form.nextDay')"
          name="next_contact_date"
        >
          <template #default="cell">
            <div
              class="cell"
              :class="{
                current: cell.isCurrent,
                'cursor-default bg-light-dark': disabledDate(cell.date),
              }"
            >
              <span class="text">{{ cell.text }}</span>
            </div>
          </template>
        </el-date-picker>
      </div>
      <!--end::Input-->
    </div>

    <div class="row mt-5" v-if="nextContactEnabled">
      <!--begin::Col-->
      <div class="col">
        <textarea
          name="next_contact_message"
          :placeholder="translate('form.note')"
          class="form-control form-control-lg form-control-solid"
          v-model="eventData.next_contact_message"
        />
      </div>
      <!--end::Col-->
    </div>
    <div class="card-footer">
      <button
        :disabled="!eventData.contacted_via"
        ref="submitButtonRef"
        type="submit"
        id="user_contact_event_submit"
        class="btn btn-primary"
      >
        <span class="indicator-label">
          {{ translate("save", "common") }}
        </span>
        <span class="indicator-progress">
          {{ translate("pleaseWait", "common") }}
          <span
            class="spinner-border spinner-border-sm align-middle ms-2"
          ></span>
        </span>
      </button>
    </div>
  </Form>
  <!--end::Form-->
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
import { Notification, User, UserContactEvent } from "@/store/interfaces/User";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { useToast } from "vue-toastification";
import moment from "moment/moment";
import { useTranslate } from "@/composables/translate";
import { formatDate } from "@/core/helpers/date";

export default defineComponent({
  emits: ["save"],
  props: {
    user: {
      type: Object as () => User,
      required: true,
    },
    reason: {
      type: String,
      required: true,
    },
  },
  name: "user-contact-event-form",
  components: {
    Form,
  },
  setup(props, { emit }) {
    const eventData = reactive<UserContactEvent>({} as UserContactEvent);
    const loading = ref(false);
    const { translate } = useTranslate("userContactEvents");
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const validationSchema = Yup.object().shape({});

    watch(
      () => props.user.id,
      () => {
        nextContactEnabled.value = false;
        eventData.reason = props.reason;
        eventData.user_id = props.user.id;
        eventData.contacted_via = null;
        eventData.next_contact_date = null;
        eventData.next_contact_message = null;
        eventData.user_notification_id = null;
        eventData.result = "";
        loadNotifications();
      }
    );

    const notifications = computed<Notification[]>(() => {
      return store.getters.getNotifications as Notification[];
    });
    onMounted(() => {
      loadNotifications();
    });
    const loadNotifications = () => {
      eventData.reason = props.reason;
      eventData.user_id = props.user.id;
    };
    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      eventData.next_contact_date = nextContactEnabled.value
        ? eventData.next_contact_date
        : null;
      eventData.next_contact_message = nextContactEnabled.value
        ? eventData.next_contact_message
        : null;
      eventData.user_notification_id = props.user.notification_to_close?.id;
      store
        .dispatch(Actions.SAVE_USER_CONTACT_EVENT, eventData)
        .then(() => {
          useToast().success(translate("form.userContactEventAdded"));
        })
        .catch(() => {
          useToast().warning(store.getters.getLastError);
        })
        .finally(() => {
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;

            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
          loadNotifications();
          emit("save", { ...eventData, ...{} });
        });
    };

    const setVia = (via) => {
      eventData.contacted_via = via;
    };

    const nextContactEnabled = ref(false);

    const disabledDate = (date) => {
      const dateMoment = moment(date).hours(23).minutes(59).second(59);
      return dateMoment < moment();
    };

    const formatMessageDate = (date: string) => {
      return formatDate(date);
    };

    return {
      loading,
      formatMessageDate,
      notifications,
      disabledDate,
      nextContactEnabled,
      setVia,
      eventData,
      translate,
      validationSchema,
      submit,
      submitButtonRef,
    };
  },
});
</script>
