<template>
  <transition
    appear
    enter-active-class="animate__animated animate__fadeInLeft"
    leave-active-class="animate__animated animate__bounceOutRight"
  >
    <div class="col-lg-6 mb-10" v-if="innerLoading || clients.length > 0">
      <div v-loading="innerLoading">
        <clients-widget-with-contact-report
          :title="translate('title') + ' (' + clients.length + ')'"
          :table-header="tableHeader"
          :clients="clients"
          :icon="'abstract/abs037.svg'"
          :contact_reason="contact_reason"
          @save="handleReportSave($event)"
          :loading="innerLoading"
        ></clients-widget-with-contact-report>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import store from "@/store";
import ClientsWidgetWithContactReport from "@/components/widgets/isenses/ClientsWidgetWithContactReport.vue";
import { User, UserContactEvent } from "@/store/interfaces/User";
import { Actions } from "@/store/enums/StoreEnums";
import { useToast } from "vue-toastification";
import { useRoute } from "vue-router";

export default defineComponent({
  components: {
    ClientsWidgetWithContactReport,
  },
  name: "clients-training-renewal",
  emits: ["save"],
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t, te } = useI18n();

    const translate = (text: string, root?: string, params = {}): string => {
      text =
        (root !== undefined ? root + "." : "widgets.ClientsTrainingRenewal.") +
        text;
      if (text) {
        if (te(text)) {
          return t(text, params);
        } else {
          return text;
        }
      } else {
        return "";
      }
    };
    const clients = computed<User[]>(() => {
      return store.getters.getClientsWidgetData?.["training-renewal"]
        ? store.getters.getClientsWidgetData["training-renewal"]?.data
        : [];
    });

    const tableHeader = ref([
      {
        name: "",
        key: "avatar",
        sortable: false,
        width: 25,
      },
      {
        name: translate("firstName", "users.table.header"),
        key: "name",
      },
      {
        name: "",
        key: "actions",
        sortable: false,
      },
    ]);
    const contact_reason = "training-renewal";

    const handleReportSave = (event: UserContactEvent) => {
      emit("save", event);
    };
    const innerLoading = ref(false);
    const shouldFilterClientsWithTraining = computed(() => {
      return store.getters["shouldFilterClientsWithTraining"];
    });
    const route = useRoute();
    // watch for route change
    watch(
      () => route.path,
      async () => {
        loadData();
      }
    );
    watch(
      () => shouldFilterClientsWithTraining.value,
      async () => {
        loadData();
      }
    );
    const loadData = () => {
      innerLoading.value = true;
      store
        .dispatch(Actions.LOAD_CLIENTS_WIDGET_DATA, {
          widgets: [contact_reason],
          from_url: route.path,
          in_training: shouldFilterClientsWithTraining.value,
        })
        .catch(() => {
          useToast().warning(store.getters.getLastError);
        })
        .finally(() => {
          innerLoading.value = false;
        });
    };
    onMounted(() => {
      loadData();
    });
    return {
      innerLoading,
      handleReportSave,
      tableHeader,
      contact_reason,
      clients,
      translate,
    };
  },
});
</script>
