<template>
  <div v-if="currentUser.role_id > 3" class="row">
    <clients-after-diagnostic
      @save="handleReportSave($event)"
      :loading="loading"
    ></clients-after-diagnostic>

    <clients-training-renewal
      @save="handleReportSave($event)"
      :loading="loading"
    ></clients-training-renewal>
    <!--    <clientsWithoutTraining-->
    <!--      @save="handleReportSave($event)"-->
    <!--      :loading="loading"-->
    <!--    ></clientsWithoutTraining>-->
    <ClientsEndingProgram
      @save="handleReportSave($event)"
      :loading="loading"
    ></ClientsEndingProgram>
    <ClientsOrderDiagnostic
      @save="handleReportSave($event)"
      :loading="loading"
    ></ClientsOrderDiagnostic>
    <ClientsFirstWeekOfTraining
      @save="handleReportSave($event)"
      :loading="loading"
    ></ClientsFirstWeekOfTraining>
    <ClientsLeads
      @save="handleReportSave($event)"
      :loading="loading"
    ></ClientsLeads>
    <ClientsScheduledToContact
      @save="handleReportSave($event)"
      :loading="loading"
    ></ClientsScheduledToContact>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import store from "@/store";
import { User, UserContactEvent } from "@/store/interfaces/User";
import ClientsEndingProgram from "@/components/widgets/isenses/ClientsEndingProgram.vue";
import ClientsOrderDiagnostic from "@/components/widgets/isenses/ClientsOrderDiagnostic.vue";
import ClientsFirstWeekOfTraining from "@/components/widgets/isenses/ClientsFirstWeekOfTraining.vue";
import ClientsLeads from "@/components/widgets/isenses/ClientsLeads.vue";
import ClientsScheduledToContact from "@/components/widgets/isenses/ClientsScheduledToContact.vue";
import ClientsAfterDiagnostic from "@/components/widgets/isenses/ClientsAfterDiagnosticTraining.vue";
import ClientsTrainingRenewal from "@/components/widgets/isenses/ClientsTrainingRenewal.vue";

export default defineComponent({
  components: {
    ClientsAfterDiagnostic,
    ClientsScheduledToContact,
    ClientsLeads,
    ClientsFirstWeekOfTraining,
    ClientsOrderDiagnostic,
    ClientsEndingProgram,
    ClientsTrainingRenewal,
    // clientsWithoutTraining,
  },
  name: "clients-widgets-group",

  setup() {
    const loading = ref<boolean>(false);
    const currentUser = computed(() => {
      return store.getters["currentUser"] as User;
    });

    const widgetsData = computed(() => {
      return store.getters["getClientsWidgetData"] as User;
    });

    const handleReportSave = (event: UserContactEvent) => {
      for (let i = 0; i < widgetsData.value[event.reason].data.length; i++) {
        if (widgetsData.value[event.reason].data[i].id === event.user_id) {
          if (event.contacted_via !== "does_not_pick_up") {
            widgetsData.value[event.reason].data.splice(i, 1);
          }
        }
      }
      widgetsData.value[event.reason].data.splice(
        0,
        widgetsData.value[event.reason].data.length,
        ...widgetsData.value[event.reason].data
      );
    };

    return {
      handleReportSave,
      loading,
      currentUser,
    };
  },
});
</script>
