<template>
  <!--begin::Details-->
  <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
    <!--begin: Pic-->
    <div class="me-7 mb-4">
      <div
        class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative"
      >
        <div
          class="symbol-label"
          :style="{ backgroundImage: `url(${userAvatar})` }"
        ></div>
        <div
          v-if="user.changed"
          class="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"
        ></div>
      </div>

      <div v-if="currentUser.role_id > 3">
        <el-tooltip
          v-if="
            currentUser.role_id > 4 &&
            user.filled_invoice_address &&
            !non_interactive
          "
          effect="dark"
          v-bind:content="translate('users.toolTips.createInvoice')"
          placement="top-end"
        >
          <router-link
            :to="`/add-invoice-for-user/${user.id}`"
            class="btn btn-link btn-active-color-primary btn-color-primary"
            href="#"
          >
            <span class="svg-icon svg-icon-2x">
              <inline-svg src="/media/icons/duotune/general/gen005.svg" />
            </span>
          </router-link>
        </el-tooltip>

        <el-tooltip
          v-if="canLogAs(user)"
          effect="dark"
          v-bind:content="
            translate('loginAs', 'users.toolTips', {
              user: `${user.name} ${user.surname}`,
            })
          "
          placement="top-end"
        >
          <a
            @click="loginAsUser(user)"
            class="btn btn-color-mutted btn-link btn-active-color-danger"
            href="#"
          >
            <span class="svg-icon svg-icon-2x">
              <inline-svg src="/media/icons/duotune/abstract/abs037.svg" />
            </span>
          </a>
        </el-tooltip>

        <el-tooltip
          v-if="!non_interactive"
          effect="dark"
          v-bind:content="
            translate(
              !user.invited ? 'invite' : 'inviteAgain',
              'users.toolTips',
              {
                user: `${user.name} ${user.surname}`,
              }
            )
          "
          placement="top-end"
        >
          <a
            @click="inviteUser(user)"
            class="btn btn-link btn-active-color-primary"
            :class="user.invited ? 'btn-color-success' : 'btn-color-muted'"
            href="#"
          >
            <span class="svg-icon svg-icon-2x">
              <inline-svg src="/media/icons/duotune/communication/com002.svg" />
            </span>
          </a>
        </el-tooltip>
        <el-tooltip
          effect="dark"
          v-bind:content="translate('users.toolTips.loggedThisWeek')"
          placement="top-end"
        >
          <span
            v-if="currentUser.role_id > 2"
            class="me-2 bi bi-circle-fill"
            :class="user.logged_this_week ? 'text-success' : 'text-danger'"
          >
          </span>
        </el-tooltip>

        <el-tooltip
          effect="dark"
          v-bind:content="translate('users.toolTips.programsStatus')"
          placement="top-end"
        >
          <span
            v-if="currentUser.role_id > 2"
            class="me-2 bi bi-square-fill"
            :class="
              user.programs_status ? 'text-' + user.programs_status : 'd-none'
            "
          >
          </span>
        </el-tooltip>

        <el-tooltip
          effect="dark"
          v-bind:content="translate('users.toolTips.trainingsStatus')"
          placement="top-end"
        >
          <span
            v-if="currentUser.role_id > 2"
            class="me-2 bi bi-triangle-fill"
            :class="
              user.trainings_status ? 'text-' + user.trainings_status : 'd-none'
            "
          >
          </span>
        </el-tooltip>

        <el-tooltip
          effect="dark"
          v-bind:content="translate('users.toolTips.programPaidStatus')"
          placement="top-end"
        >
          <span
            v-if="currentUser.role_id > 2"
            class="me-2 bi bi-star-fill"
            :class="
              user.program_paid_status
                ? 'text-' + user.program_paid_status
                : 'd-none'
            "
          >
          </span>
        </el-tooltip>
      </div>

      <div v-if="currentUser.role_id > 3">
        <span class="text-gray-400 me-2">
          <span
            class="svg-icon svg-icon-4 me-1"
            :class="user.privacy.gdpr ? 'svg-icon-success' : 'svg-icon-warning'"
          >
            <inline-svg
              :src="
                '/media/icons/duotune/general/gen0' +
                (user.privacy.gdpr ? '43' : '40') +
                '.svg'
              "
            />
          </span>

          Gdpr</span
        >
        <span class="text-gray-400 me-2">
          <span
            class="svg-icon svg-icon-4 me-1"
            :class="
              user.privacy.media ? 'svg-icon-success' : 'svg-icon-warning'
            "
          >
            <inline-svg
              :src="
                '/media/icons/duotune/general/gen0' +
                (user.privacy.media ? '43' : '40') +
                '.svg'
              "
            />
          </span>

          Media</span
        >
        <span class="text-gray-400 me-2">
          <span
            class="svg-icon svg-icon-4 me-1"
            :class="
              user.privacy.research ? 'svg-icon-success' : 'svg-icon-warning'
            "
          >
            <inline-svg
              :src="
                '/media/icons/duotune/general/gen0' +
                (user.privacy.research ? '43' : '40') +
                '.svg'
              "
            />
          </span>

          {{ translate("research_badge_label", "privacy") }}</span
        >

        <el-tooltip
          v-if="!non_interactive"
          effect="dark"
          v-bind:content="translate('privacyChanged', 'users.toolTips')"
          placement="top-end"
        >
          <span
            v-if="user.privacy_change?.changed"
            style="bottom: 2px"
            class="svg-icon svg-icon-1 me-1 cursor-pointer svg-icon-info position-relative"
            @click="checkPrivacyChange(user.privacy_change.record)"
          >
            <inline-svg :src="'/media/icons/duotune/general/gen044.svg'" />
          </span>
        </el-tooltip>
      </div>
    </div>
    <!--end::Pic-->

    <!--begin::Info-->
    <div class="flex-grow-1">
      <!--begin::Title-->
      <div
        class="d-flex justify-content-between align-items-start flex-wrap mb-2"
      >
        <!--begin::User-->
        <div class="d-flex flex-column">
          <!--begin::Name-->
          <div class="flex-wrap d-flex align-items-center mb-2">
            <a
              href="#"
              class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
              >{{ user.name }} {{ user.surname }}</a
            >
            <span
              v-if="user.employment"
              class="m-1 badge badge-info text-white"
              >{{ user.employment }}</span
            >

            <span
              v-if="user.developer"
              class="m-1 badge badge-warning text-white"
              >Developer</span
            >
            <span v-if="user.therapy" class="m-1 badge badge-success">{{
              translate("users.account.badges.training")
            }}</span>

            <span
              v-if="user.role_id === 2 && user.user_group"
              class="m-1 badge badge-dark"
              >{{ user.user_group.name }}</span
            >

            <span
              :key="`sport-id-${sport.id}`"
              v-for="sport in user.sports"
              class="m-1 badge badge-secondary"
              >{{ sport.name }}</span
            >
          </div>
          <!--end::Name-->

          <!--begin::Info-->
          <div
            class="d-flex flex-wrap fw-bold fs-6 pe-2"
            :class="user.specializations?.length ? 'mb-0' : 'mb-4'"
          >
            <span
              class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
            >
              <span class="svg-icon svg-icon-4 me-1">
                <inline-svg
                  src="/media/icons/duotune/communication/com006.svg"
                />
              </span>
              {{ translate(`users.roles.${user.role.name}.title`) }}
            </span>
            <span
              class="d-flex align-items-center text-gray-400 text-hover-primary mb-2 me-1"
            >
              <span class="svg-icon svg-icon-4 me-1">
                <inline-svg
                  src="/media/icons/duotune/communication/com011.svg"
                />
              </span>
              <a :href="`mailto:${user.email}`">{{ user.email }}</a>
            </span>
            <span
              v-if="user.phone"
              class="d-flex align-items-center text-gray-400 text-hover-primary mb-2 me-2"
            >
              <span class="svg-icon svg-icon-4 me-1">
                <inline-svg src="/media/icons/duotune/electronics/elc002.svg" />
              </span>
              <a :href="`tel:${user.phone}`">{{ user.phone }}</a>
            </span>

            <span
              v-if="user.phone"
              class="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
            >
              <span class="svg-icon svg-icon-4 me-1">
                <inline-svg
                  src="/media/icons/duotune/communication/com003.svg"
                />
              </span>
              <a :href="`sms:${user.phone}`">SMS</a>
            </span>
          </div>

          <div
            v-if="userLegalRepresentative"
            class="d-flex flex-wrap fw-bold fs-6 pe-2"
            :class="user.specializations?.length ? 'mb-0' : 'mb-4'"
          >
            <span
              class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
            >
              <span class="svg-icon svg-icon-4 me-1">
                <inline-svg
                  src="/media/icons/duotune/communication/com006.svg"
                />
              </span>
              {{ translate(`users.roles.legalRepresentative.title`) }}
            </span>
            <span
              class="d-flex align-items-center text-gray-400 text-hover-primary mb-2 me-1"
            >
              <span class="svg-icon svg-icon-4 me-1">
                <inline-svg
                  src="/media/icons/duotune/communication/com011.svg"
                />
              </span>
              <a :href="`mailto:${userLegalRepresentative.email}`">{{
                userLegalRepresentative.email
              }}</a>
            </span>
            <span
              v-if="userLegalRepresentative.phone"
              class="d-flex align-items-center text-gray-400 text-hover-primary mb-2 me-2"
            >
              <span class="svg-icon svg-icon-4 me-1">
                <inline-svg src="/media/icons/duotune/electronics/elc002.svg" />
              </span>
              <a :href="`tel:${userLegalRepresentative.phone}`">{{
                userLegalRepresentative.phone
              }}</a>
            </span>

            <span
              v-if="user.phone"
              class="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
            >
              <span class="svg-icon svg-icon-4 me-1">
                <inline-svg
                  src="/media/icons/duotune/communication/com003.svg"
                />
              </span>
              <a :href="`sms:${userLegalRepresentative.phone}`">SMS</a>
            </span>
          </div>
          <!--end::Info-->

          <!--begin::Specializations-->
          <div
            v-if="user.specializations?.length"
            class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2"
          >
            <span
              v-for="specialization in user.specializations"
              :key="specialization.id"
              class="m-1 badge badge-light-primary"
              >{{ specialization.name }}</span
            >
          </div>
          <div v-if="age" class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
            <span
              class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
            >
              {{ translate("users.account.overview.form.age") }}
              <span class="ms-2">
                {{ age }}
              </span>
            </span>
          </div>
          <!--end::Specializations-->

          <!--begin::Specialists-->
          <div
            v-if="
              false && user.role_id === 2 && user.assigned_specialists?.length
            "
          >
            <span
              class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
            >
              <span class="svg-icon svg-icon-4 me-1">
                <inline-svg src="/media/icons/duotune/coding/cod002.svg" />
              </span>
              {{ translate(`users.account.settings.form.specialists`) }}
            </span>
            <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
              <span
                v-for="specialist in user.assigned_specialists"
                :key="specialist.id"
                class="m-1 badge badge-light-primary"
                >{{ specialist.name }} {{ specialist.surname }} (
                {{ specialist.phone }})</span
              >
            </div>
          </div>
          <!--end::Specializations-->

          <!--begin::Overseers-->
          <div
            v-if="
              user.role_id === 2 && user.overseers?.length && userCoordinator
            "
          >
            <span
              class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
            >
              <span class="svg-icon svg-icon-4 me-1">
                <inline-svg src="/media/icons/duotune/coding/cod002.svg" />
              </span>
              {{ translate(`users.overseers.roles.coordinator.title`) }}
            </span>
            <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
              <span class="m-1 badge badge-light-primary"
                >{{ userCoordinator.name }} {{ userCoordinator.surname }} (
                {{ userCoordinator.phone }})</span
              >
            </div>
          </div>
          <!--end::Overseers-->

          <!--begin::Info-->
          <div
            v-if="currentUser.role_id > 2 && user.logins?.length"
            class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2"
          >
            <span
              class="c d-flex align-items-center text-gray-400 me-5 mb-2"
              :class="
                !non_interactive ? 'cursor-pointer text-hover-primary' : ''
              "
              :data-bs-toggle="!non_interactive ? 'modal' : ''"
              :data-bs-target="'#login-history_modal'"
            >
              <span class="svg-icon svg-icon-4 me-1">
                <inline-svg src="/media/icons/duotune/arrows/arr042.svg" />
              </span>

              {{ translate("users.account.lastLogin") }}
              <span class="ms-2">
                {{ formatLoginDateTime(user.logins[0].created_at) }}
              </span>
            </span>
          </div>

          <div
            v-if="currentUser.role_id > 2 && user.last_contact_event"
            class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2"
          >
            <span
              class="d-flex align-items-center text-gray-400 me-5 mb-2"
              :class="
                !non_interactive ? 'cursor-pointer text-hover-primary' : ''
              "
              :data-bs-toggle="!non_interactive ? 'modal' : ''"
              :data-bs-target="'#user-contact-events'"
            >
              <span class="svg-icon svg-icon-4 me-1">
                <inline-svg
                  src="/media/icons/duotune/communication/com003.svg"
                />
              </span>

              {{ translate("users.account.lastContacted") }}
              <span class="ms-2">
                {{ formatLoginDateTime(user.last_contact_event.created_at) }}
                <small
                  >({{
                    translate(
                      "reasons." + user.last_contact_event.reason,
                      "userContactEvents"
                    )
                  }})</small
                >
              </span>
            </span>
          </div>
        </div>
        <!--end::User-->
      </div>
      <!--end::Title-->
    </div>
    <!--end::Info-->
  </div>
  <!--end::Details-->
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import store from "@/store";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  getUserAge,
  showUserAvatar,
  User,
  UserPrivacyHistoryRecord,
} from "@/store/interfaces/User";
import { useTranslate } from "@/composables/translate";
import { formatDateTime } from "@/core/helpers/date";
import { Actions } from "@/store/enums/StoreEnums";
import { useToast } from "vue-toastification";
import { getMailTemplate } from "@/core/plugins/i18n";
import { Contact } from "@/store/interfaces/Contact";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "account-details",
  props: {
    non_interactive: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  components: {},
  setup() {
    const mailTemplate = getMailTemplate("inviteUser");
    const loading = ref(false);
    const currentUser = computed(() => {
      return store.getters["currentUser"] as User;
    });
    const user = computed(() => {
      return store.getters.getLoadedUser as User;
    });

    const userCoordinator = computed<User | undefined>(() => {
      let coordinator: User | undefined = undefined;
      if (user.value.overseers && user.value.overseers.length) {
        coordinator = user.value.overseers.find(
          (overseer) => overseer.overseer_role === "coordinator"
        );
      }
      return coordinator;
    });

    const formatLoginDateTime = (date: string) => {
      return formatDateTime(date);
    };

    const { t, translate } = useTranslate();
    const userAvatar = computed(() => {
      return showUserAvatar(user.value);
    });

    const inviteUser = (user: User) => {
      Swal.fire({
        html: t("users.table.inviteUserQuestion", {
          user: user.name + " " + user.surname,
        }),
        icon: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn fw-bold btn-light-success",
          cancelButton: "btn fw-bold btn-light-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          loading.value = true;
          store
            .dispatch(Actions.INVITE_USER, {
              email: user.email,
              mail: mailTemplate,
            })
            .then((invitedUser) => {
              user.invited = invitedUser.invited;
              useToast().success(
                t("users.table.userInvited", {
                  user: `${invitedUser.name} ${invitedUser.surname}`,
                })
              );
            })
            .catch(() => {
              useToast().warning(store.getters.getLastError);
            })
            .finally(() => {
              loading.value = false;
            });
        }
      });
    };

    const checkPrivacyChange = (change: UserPrivacyHistoryRecord) => {
      // Send login request
      store
        .dispatch(Actions.CHECK_PRIVACY_CHANGE, { ...change })
        .then((result) => {
          if (result) {
            user.value.privacy_change.changed = false;
          }
        });
    };

    const userLegalRepresentative = computed<Contact | null>(() => {
      return user.value.other_contacts?.filter(
        (contact) => ["father", "mother"].indexOf(contact.relationship) > -1
      )[0];
    });
    const age = computed(() => {
      return getUserAge(user.value);
    });

    const router = useRouter();
    const loginAsUser = (user: User) => {
      Swal.fire({
        html: t("users.table.loginAsUserQuestion", {
          user: user.name + " " + user.surname,
        }),
        icon: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn fw-bold btn-light-success",
          cancelButton: "btn fw-bold btn-light-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          loading.value = true;
          store
            .dispatch(Actions.LOGIN, {
              email: user.email,
              login_as_other_user: true,
              requested_by: currentUser.value.id,
            })
            .then((loggedUser) => {
              useToast().success(
                t("users.table.userLoginChanged", {
                  user: `${loggedUser.name} ${loggedUser.surname}`,
                })
              );
              router.push("/dashboard").finally(() => {
                window.location.reload();
              });
            })
            .catch(() => {
              useToast().warning(store.getters.getLastError);
              router.push("sign-in");
            })
            .finally(() => {
              loading.value = false;
            });
        }
      });
    };
    const canLogAs = (user: User) => {
      return user.id !== currentUser.value.id && currentUser.value.role_id > 5;
    };

    return {
      canLogAs,
      loginAsUser,
      userCoordinator,
      userLegalRepresentative,
      age,
      checkPrivacyChange,
      currentUser,
      user,
      translate,
      userAvatar,
      formatLoginDateTime,
      inviteUser,
    };
  },
});
</script>
