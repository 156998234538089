import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col-lg-6 mb-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_clients_widget_with_contact_report = _resolveComponent("clients-widget-with-contact-report")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_Transition, {
    appear: "",
    "enter-active-class": "animate__animated animate__fadeInLeft",
    "leave-active-class": "animate__animated animate__bounceOutRight"
  }, {
    default: _withCtx(() => [
      (_ctx.innerLoading || _ctx.clients.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _withDirectives((_openBlock(), _createElementBlock("div", null, [
              _createVNode(_component_clients_widget_with_contact_report, {
                title: _ctx.translate('title') + ' (' + _ctx.clients.length + ')',
                "table-header": _ctx.tableHeader,
                clients: _ctx.clients,
                icon: 'medicine/med001.svg',
                contact_reason: _ctx.contact_reason,
                onSave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleReportSave($event))),
                loading: _ctx.innerLoading
              }, null, 8, ["title", "table-header", "clients", "contact_reason", "loading"])
            ])), [
              [_directive_loading, _ctx.innerLoading]
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}